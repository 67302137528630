import { initializeApp, FirebaseApp } from "firebase/app";
import { getFirestore, Firestore, initializeFirestore, FirestoreSettings } from "firebase/firestore";
import { getAuth, Auth } from "firebase/auth";
import { getFunctions, Functions } from "firebase/functions";
import { getMessaging, Messaging } from "firebase/messaging";
import { getAnalytics, Analytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

const app: FirebaseApp = initializeApp(firebaseConfig);


/* 
// Determine the environment and set the database ID accordingly
const isLocalhost = window.location.hostname === 'localhost';
const databaseId = isLocalhost ? process.env.REACT_APP_DEV_DATABASE_ID : undefined;

// Log the database being used
console.log(`Using Firestore database ID: ${databaseId ? databaseId : 'default'}`);

// Initialize Firestore with the appropriate database ID
let firestore: Firestore;
if (databaseId) {
  // Initialize Firestore with the specific database ID for development
  firestore = initializeFirestore(app, {
    experimentalForceLongPolling: true
  }, databaseId);
} else {
  // Initialize Firestore with the default database
  firestore = getFirestore(app);
}
*/


const auth: Auth = getAuth(app);
const functions: Functions = getFunctions(app); 
const messaging: Messaging = getMessaging(app);
const analytics: Analytics = getAnalytics(app);
const firestore: Firestore = getFirestore(app)

export { app, auth, firestore, functions, messaging, analytics };
