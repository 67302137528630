import React, { useEffect, useRef } from 'react';
import '../Styles/AudioPlayer.css';
import { IoVolumeMedium } from "react-icons/io5";


interface VolumeSliderProps {
  volume: number;  
  onVolumeChange: (newVolume: number) => void;  
}

export const VolumeSlider = ({ volume, onVolumeChange }: VolumeSliderProps) => {
  const audioRef = useRef<{ context: AudioContext, gainNode: GainNode }>();

  useEffect(() => {
    const AudioContext = window.AudioContext || (window as any).webkitAudioContext;
    const audioContext = new AudioContext();
    const gainNode = audioContext.createGain();
    gainNode.connect(audioContext.destination);
    audioRef.current = { context: audioContext, gainNode };
  }, []);

  const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newVolume = parseInt(event.target.value, 10);
    onVolumeChange(newVolume);
    setVolume(newVolume / 100);
  };

  const setVolume = (newVolume: number) => {
    if (audioRef.current) {
      const { context, gainNode } = audioRef.current;
      gainNode.gain.setValueAtTime(newVolume, context.currentTime);
    }
  };

  const filledStyle: React.CSSProperties = {
    width: `${volume}%`,
    height: '100%',
    backgroundColor: '#A9A9A9',
    borderRadius: '10px',
    position: 'absolute',
    left: 0,
    top: 0
  };

  const trackStyle: React.CSSProperties = {
    position: 'relative',
    width: '120px',
    height: '10px',
    backgroundColor: '#D3D3D3',
    borderRadius: '10px'
  };

  const sliderStyle: React.CSSProperties = {
    width: '100%',
    position: 'absolute',
    top: 0,
    height: '10px',
    opacity: 0,
    background: 'transparent',
    WebkitAppearance: 'none',
    appearance: 'none'
  };

  return (
    <div className='volume-container'>
      <IoVolumeMedium className='volume-icon'/>
    <div style={trackStyle}>
      <div style={filledStyle}></div>
      <input
        type="range"
        min="0"
        max="100"
        value={volume}
        className="volume-slider"
        onChange={handleSliderChange}
        style={sliderStyle}
      />
    </div>
    </div>
  );
};