import React, { useState, useRef, useEffect } from "react";
import "../Styles/AccountDetails.css";
import { Spinner } from "../GlobalStyles/Spinner";
import { Toolbar } from "../Components/Toolbar";
import { useNavigate } from "react-router-dom";
import { FetchAccountDetails } from "../Services/AccountDetails";
import { auth } from "../firebase";
import AvatarEditor from "react-avatar-editor";
import { UploadAvatarService } from "../Services/AvatarService";
import { firestore } from "../firebase";
import { doc, updateDoc } from "firebase/firestore";
import { FaUserCircle } from "react-icons/fa";
import { MdInfoOutline } from "react-icons/md";
import {
  validateName,
  validateEmail,
  validateUserName,
  validateArtistName,
} from "../Services/RegisterValidation";

export const Account = () => {
  const navigate = useNavigate();
  const [avatarFile, setAvatarFile] = useState<File | null>(null);
  const [editorVisible, setEditorVisible] = useState(false);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [showSaveMessage, setShowSaveMessage] = useState(false);

  const [userData, setUserData] = useState({
    avatarUrl: "",
    name: "",
    email: "",
    userName: "",
    artistName: "",
    userType: "",
    stateName: "",
    regionName: "",
  });

  const [errors, setErrors] = useState({
    nameError: "",
    emailError: "",
    userNameError: "",
    artistNameError: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const avatarEditorRef = useRef<any>(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        fetchAccountDetails();
      } else {
        setError("No user found.");
        setIsLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchAccountDetails = async () => {
    setIsLoading(true);
    const fetcher = new FetchAccountDetails();
    try {
      const userDetails = await fetcher.getAccountDetails();
      if (userDetails) {
        setUserData(userDetails);
      } else {
        setError("No user details found.");
      }
    } catch (err) {
      console.error(err);
      setError("Failed to fetch account details.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleAvatarChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setAvatarFile(e.target.files[0]);
      setEditorVisible(true);
    }
  };

  const handleEditorSave = async () => {
    if (avatarEditorRef.current) {
      setIsLoading(true);
      const canvas = avatarEditorRef.current.getImageScaledToCanvas();
      canvas.toBlob(async (blob: Blob | null) => {
        try {
          if (blob) {
            const previewUrl = URL.createObjectURL(blob);
            setPreviewUrl(previewUrl);

            await UploadAvatarService.saveAvatarToS3AndFirestore(
              new File([blob], `avatar-${Date.now()}.png`, {
                type: "image/png",
              })
            );
            console.log("Avatar upload successful.");

            await fetchAccountDetails();
          }
        } catch (error) {
          console.error("Error saving avatar:", error);
        } finally {
          setIsLoading(false);
          setEditorVisible(false);
        }
      }, "image/png");
    }
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newName = e.target.value;
    const error = validateName(newName);
    setUserData({ ...userData, name: newName });
    setErrors({ ...errors, nameError: error });
  };

  const handleEmailChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    const error = await validateEmail(newEmail);
    setUserData({ ...userData, email: newEmail });
    setErrors({ ...errors, emailError: error });
  };

  const handleUserNameChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newUserName = e.target.value;
    const error = await validateUserName(newUserName);
    setUserData({ ...userData, userName: newUserName });
    setErrors({ ...errors, userNameError: error });
  };

  const handleArtistNameChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newArtistName = e.target.value;
    const error = await validateArtistName(newArtistName);
    setUserData({ ...userData, artistName: newArtistName });
    setErrors({ ...errors, artistNameError: error });
  };

  const saveAccountDetails = async () => {
    setIsLoading(true);
    const user = auth.currentUser;

    if (user) {
      try {
        await updateDoc(doc(firestore, "users", user.uid), {
          name: userData.name,
          userName: userData.userName,
        });

        if (user.email !== userData.email) {
          const fetchDetails = new FetchAccountDetails();
          await fetchDetails.changeUserEmail(userData.email);
          console.log("Email updated successfully.");
        }

        await fetchAccountDetails();
        setShowSaveMessage(true);
        setTimeout(() => setShowSaveMessage(false), 3000);
      } catch (error: unknown) {
        console.error("Error updating account details:", error);
        if (error instanceof Error) {
          setError("Failed to update account details. " + error.message);
        } else {
          setError(
            "Failed to update account details. An unknown error occurred."
          );
        }
      } finally {
        setIsLoading(false);
      }
    } else {
      setError("No user logged in.");
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    navigate("/home");
  };

  return (
    <div className="account-details-container">
      <form className="account-details-form">
        <p className="account-details-header">Account Details</p>
        <p className="account-details-subheader">Manage Your Account</p>
        {showSaveMessage && (
          <p
            className="account-details-save-msg"
            style={{ display: showSaveMessage ? "block" : "none" }}
          >
            Changes Saved!
          </p>
        )}

        <hr className="account-details-divider"></hr>

        <section className="avatar-settings-group">
          <div className="avatar-image">
            {userData.avatarUrl ? (
              <img
                src={userData.avatarUrl}
                alt="User Avatar"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "50%",
                }}
              />
            ) : (
              <div>
                <FaUserCircle className="default-avatar" />
              </div>
            )}
          </div>
          <div className="avatar-info-group">
            <p className="avatar-header">Profile Avatar</p>
            <p className="avatar-subheader">Add a JPG or PNG</p>
            <div className="file-input-container">
              <input
                type="file"
                id="avatar-input"
                className="avatar-input"
                onChange={handleAvatarChange}
                accept="image/png, image/jpeg"
              />
              <label htmlFor="avatar-input" className="avatar-add-image-btn">
                Choose File
              </label>
            </div>
            {editorVisible && avatarFile && (
              <>
                <AvatarEditor
                  ref={avatarEditorRef}
                  image={avatarFile}
                  width={250}
                  height={250}
                  border={50}
                  color={[255, 255, 255, 0.6]}
                  scale={1.2}
                  rotate={0}
                />
                <button
                  className="save-avatar-btn"
                  type="button"
                  onClick={handleEditorSave}
                >
                  Save Avatar
                </button>
              </>
            )}
          </div>
        </section>

        <section className="input-label-row">
          <div className="label-input-group">
            <label className="account-details-form-label">Name</label>
            <input
              className="account-details-form-input"
              value={userData.name}
              onChange={handleNameChange}
            />
            {errors.nameError && (
              <p className="error-message">{errors.nameError}</p>
            )}
          </div>
          <div className="label-input-group">
            <label className="account-details-form-label">Email</label>
            <input
              className="account-details-form-input non-editable-input"
              value={userData.email}
              onChange={handleEmailChange}
              readOnly
            />
            {errors.emailError && (
              <p className="error-message">{errors.emailError}</p>
            )}
          </div>
        </section>

        <section className="input-label-row">
          {/* Conditional rendering based on userType */}
          {userData.userType === "Artist" && (
            <div className="label-input-group">
              <label className="account-details-form-label">Artist Name</label>
              <input
                className="account-details-form-input non-editable-input"
                value={userData.artistName}
                onChange={handleArtistNameChange}
                readOnly
              />
              {errors.artistNameError && (
                <p className="error-message">{errors.artistNameError}</p>
              )}
            </div>
          )}

          {userData.userType === "Listener" && (
            <div className="label-input-group">
              <label className="account-details-form-label">Username</label>
              <input
                className="account-details-form-input non-editable-input"
                value={userData.userName}
                onChange={handleUserNameChange}
                readOnly
              />
              {errors.userNameError && (
                <p className="error-message">{errors.userNameError}</p>
              )}
            </div>
          )}

          <div className="label-input-group">
            <label className="account-details-form-label">User Type</label>
            <input
              className="account-details-form-input non-editable-input"
              value={userData.userType}
              readOnly
            />
          </div>
        </section>
        <section className="input-label-row">
          <div className="label-input-group">
            <label className="account-details-form-label">State</label>
            <input
              className="account-details-form-input non-editable-input"
              value={userData.stateName}
              readOnly
            />
          </div>
          <div className="label-input-group">
            <label className="account-details-form-label">Region</label>
            <input
              className="account-details-form-input non-editable-input"
              value={userData.regionName}
              readOnly
            />
          </div>
        </section>

        <span className="account-info-container">
          <MdInfoOutline className="account-info-eyeball" />
          {userData.userType === "Artist" ? (
            <p className="account-info-text">
              Email, Artist Name, State, and Region will be editable in the
              future.
            </p>
          ) : (
            <p className="account-info-text">
              Email, Username, State, and Region will be editable in the future.
            </p>
          )}
        </span>

        <div className="account-details-btn-group">
          <div>
            <button className="close-account-details-btn" onClick={handleClose}>
              Close
            </button>
            <button
              className="save-account-details-btn"
              type="button"
              onClick={saveAccountDetails}
            >
              Save
            </button>
          </div>
        </div>
        {isLoading && (
          <div className="overlay">
            <Spinner />
          </div>
        )}
      </form>
    </div>
  );
};
