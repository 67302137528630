import React, { useState, useEffect, ChangeEvent } from "react";
import "../Styles/AudioUpload.css";
import { FaPlay, FaPause, FaHeart, FaRegTrashAlt } from "react-icons/fa";
import { IoPlay } from "react-icons/io5";
import { firestore, auth } from "../firebase";
import {
  collection,
  query,
  where,
  orderBy,
  onSnapshot,
  limit,
  startAfter,
  getDocs,
  QueryDocumentSnapshot,
} from "firebase/firestore";
import { UploadSingleForm } from "./UploadSingleForm";
import { UploadAlbumForm } from "./UploadAlbumForm";
import { Track } from "../Types/Types";
import { genres } from "../Utilities/StaticFormData";
import { updateTrack, deleteTrack } from "../Services/EditUploadService";
import { AiFillInfoCircle } from "react-icons/ai";

export const AudioUpload = () => {
  const [tracks, setTracks] = useState<Track[]>([]);
  const [sortOrder, setSortOrder] = useState("newest");
  const [lastVisible, setLastVisible] =
    useState<QueryDocumentSnapshot<Track> | null>(null);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [loadedTracksCount, setLoadedTracksCount] = useState(5);
  const [editingTrackId, setEditingTrackId] = useState<string | null>(null);
  const [currentTrack, setCurrentTrack] = useState<Partial<Track> | null>(null);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [trackToDelete, setTrackToDelete] = useState<Track | null>(null);

  const [trackData, setTrackData] = useState({
    trackTitle: "",
    featureArtists: "",
    producers: "",
    songwriters: "",
    engineers: "",
    genre: "",
  });

  useEffect(() => {
    const userId = auth.currentUser ? auth.currentUser.uid : null;
    if (!userId) return;

    const tracksRef = collection(firestore, "tracks");
    const realTimeQuery =
      sortOrder === "newest"
        ? query(
            tracksRef,
            where("userId", "==", userId),
            orderBy("uploadTimestamp", "desc")
          )
        : query(
            tracksRef,
            where("userId", "==", userId),
            orderBy("uploadTimestamp", "asc")
          );

    const unsubscribe = onSnapshot(realTimeQuery, (querySnapshot) => {
      const updatedTracks = querySnapshot.docs.map((doc) => {
        const { id, ...data } = { id: doc.id, ...doc.data() } as {
          id: string;
        } & Track;
        return { id, ...data };
      });
      setTracks(updatedTracks.slice(0, loadedTracksCount));
      setHasMore(querySnapshot.docs.length > loadedTracksCount);
    });

    return () => unsubscribe();
  }, [sortOrder, loadedTracksCount]);

  const fetchUserTracks = async (initial = false) => {
    setLoading(true);
    const userId = auth.currentUser ? auth.currentUser.uid : null;
    if (!userId) {
      setLoading(false);
      return;
    }

    const fetchLimit = initial ? loadedTracksCount : loadedTracksCount + 1; 

    let q = query(
      collection(firestore, "tracks"),
      where("userId", "==", userId),
      orderBy("uploadTimestamp", sortOrder === "newest" ? "desc" : "asc"),
      limit(fetchLimit)
    );

    if (!initial && lastVisible) {
      q = query(q, startAfter(lastVisible));
    }

    const querySnapshot = await getDocs(q);
    const fetchedTracks = querySnapshot.docs.map((doc) => {
      const { id, ...data } = { id: doc.id, ...doc.data() } as {
        id: string;
      } & Track;
      return { id, ...data };
    });

    if (!initial) {
      setTracks((prev) => [...prev, ...fetchedTracks.slice(0, 5)]);
    }
    if (querySnapshot.docs.length > 0) {
      setLastVisible(
        querySnapshot.docs[
          querySnapshot.docs.length - 1
        ] as QueryDocumentSnapshot<Track>
      );
    } else {
      setLastVisible(null);
    }
    setHasMore(querySnapshot.docs.length > loadedTracksCount);
    setLoading(false);
  };

  const loadMoreTracks = () => {
    setLoadedTracksCount((prev) => prev + 5);
    fetchUserTracks(false);
  };

  function getButtonClass(order: string) {
    return `filter-btn ${sortOrder === order ? "selected" : ""}`;
  }

  const handleEditClick = (trackId: string) => {
    const track = tracks.find((t) => t.id === trackId);
    if (track) {
      setEditingTrackId(trackId);
      setTrackData({
        trackTitle: track.trackTitle || "",
        genre: track.genre || "",
        featureArtists: Array.isArray(track.featureArtists)
          ? track.featureArtists.join(", ")
          : "",
        producers: Array.isArray(track.producers)
          ? track.producers.join(", ")
          : "",
        songwriters: Array.isArray(track.songwriters)
          ? track.songwriters.join(", ")
          : "",
        engineers: Array.isArray(track.engineers)
          ? track.engineers.join(", ")
          : "",
      }); 
    }
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    setTrackData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const prepareDataForSave = () => {
    return {
      ...trackData,
      featureArtists: trackData.featureArtists
        .split(",")
        .map((name) => name.trim())
        .filter((name) => name !== ""),
      producers: trackData.producers
        .split(",")
        .map((name) => name.trim())
        .filter((name) => name !== ""),
      songwriters: trackData.songwriters
        .split(",")
        .map((name) => name.trim())
        .filter((name) => name !== ""),
      engineers: trackData.engineers
        .split(",")
        .map((name) => name.trim())
        .filter((name) => name !== ""),
    };
  };

  const handleSave = async (trackId: string) => {
    const preparedData = prepareDataForSave();
    try {
      await updateTrack(trackId, preparedData);
      console.log("Update successful");
      handleClose();
    } catch (error) {
      console.error("Failed to update track:", error);
    }
  };

  const handleClose = () => {
    setEditingTrackId(null);
    setCurrentTrack(null);
  };

  const handleDeleteClick = (track: Track) => {
    setConfirmDelete(true);
    setTrackToDelete(track);
  };

  const handleConfirmDelete = async () => {
    if (trackToDelete) {
      try {
        await deleteTrack(trackToDelete.id);
        setTracks((prev) => prev.filter((t) => t.id !== trackToDelete.id));
        setConfirmDelete(false);
        setTrackToDelete(null);
      } catch (error) {
        console.error("Error while deleting the track: ", error);
      }
    }
  };

  const handleCancelDelete = () => {
    setConfirmDelete(false);
    setTrackToDelete(null);
  };

  return (
    <div className="audio-upload-container">
      <section className="filter-btn-container">
        <button
          onClick={() => setSortOrder("newest")}
          className={getButtonClass("newest")}
        >
          Newest
        </button>
        <button
          onClick={() => setSortOrder("oldest")}
          className={getButtonClass("oldest")}
        >
          Oldest
        </button>
      </section>
      <p className='mobile-message'><AiFillInfoCircle />To upload or edit songs, please log in on your computer</p>

      {tracks.map((track) => (
        <div
          key={track.id} 
          className={`individual-audio-upload-content ${
            editingTrackId === track.id ? "editing" : ""
          }`}
        >
          <section className="audio-upload-content">

            <p className="audio-upload-name audio-upload-spacing">
              {track.trackTitle}
            </p>
            <p className="audio-upload-streams audio-upload-spacing">
              <IoPlay className="audio-upload-icon au-play" />
              {track.allTimePlays}
            </p>
            <p className="audio-upload-likes audio-upload-spacing">
              <FaHeart className="audio-upload-icon au-like" />
              {track.likes}
            </p>

            <button
              onClick={() => handleEditClick(track.id)}
              className="audio-upload-edit-btn"
            >
              Edit
            </button>
            <button
              onClick={() => handleDeleteClick(track)}
              className="audio-upload-delete-btn"
            >
              <FaRegTrashAlt />
            </button>
          </section>
          {confirmDelete && track.id === trackToDelete?.id && (
            <div className="confirm-delete-dialog">
              <p className="delete-text">
                Are you sure you want to delete {track.trackTitle}?
              </p>
              <button className="delete-yes-btn" onClick={handleConfirmDelete}>
                Yes
              </button>
              <button
                className="delete-no-btn"
                onClick={() => setConfirmDelete(false)}
              >
                No
              </button>
            </div>
          )}
          {editingTrackId === track.id && (
            <div className="audio-upload-edit-form">
              <div className="audio-upload-form-input-group">
                <div className="audio-upload-label-input-group">
                  <label className="audio-upload-edit-label">Track Title</label>
                  <input
                    className="audio-upload-edit-input"
                    name="trackTitle"
                    value={trackData.trackTitle || ""}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="audio-upload-label-input-group">
                  <label className="audio-upload-edit-label">
                    Feature Artists
                  </label>
                  <input
                    className="audio-upload-edit-input"
                    name="featureArtists"
                    value={trackData.featureArtists || ""}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="audio-upload-label-input-group">
                  <label className="audio-upload-edit-label">Genre</label>
                  <select
                    name="genre"
                    value={trackData.genre || ""}
                    onChange={handleInputChange}
                    className="audio-upload-edit-input"
                  >
                    <option value="">Select Genre</option>
                    {genres.map((genre) => (
                      <option key={genre.genreID} value={genre.genreName}>
                        {genre.genreName}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="audio-upload-label-input-group">
                  <label className="audio-upload-edit-label">Producers</label>
                  <input
                    className="audio-upload-edit-input"
                    name="producers"
                    value={trackData.producers || ""}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="audio-upload-label-input-group">
                  <label className="audio-upload-edit-label">Songwriters</label>
                  <input
                    className="audio-upload-edit-input"
                    name="songwriters"
                    value={trackData.songwriters || ""}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="audio-upload-label-input-group">
                  <label className="audio-upload-edit-label">Engineers</label>
                  <input
                    className="audio-upload-edit-input"
                    name="engineers"
                    value={trackData.engineers || ""}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="audio-upload-form-input-group"></div>

              <button
                className="audio-upload-save-changes-btn"
                onClick={() => handleSave(track.id)}
              >
                Save
              </button>
              <button
                className="audio-upload-cancel-changes-btn"
                onClick={handleClose}
              >
                Cancel
              </button>
            </div>
          )}
        </div>
      ))}
      {hasMore && (
        <button className="audio-upload-load-more-btn" onClick={loadMoreTracks}>
          Load More
        </button>
      )}
    </div>
  );
};
