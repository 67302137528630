import React, { useState, useEffect, useRef, useContext } from "react";
import "../Styles/Toolbar.css";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { UploadSelectionModal } from "./UploadSelectionModal";
import { UploadSingleForm } from "./UploadSingleForm";
import { UploadAlbumForm } from "./UploadAlbumForm";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  doc,
  getDoc,
  getFirestore,
  query,
  collection,
  where,
  getDocs,
} from "firebase/firestore";
import { UserContext, useUser } from "../Context/UserContext";
import { firestore } from "../firebase";
import { useAuth } from "../Context/AuthContext";
import algoliasearch from "algoliasearch/lite";
import { useSearch } from "../Context/SearchContext";
import { IoMdClose } from "react-icons/io";
import { FaBell, FaCog } from "react-icons/fa";
import { notificationService } from "../Services/NotificationService";
import { Notification } from "../Types/Types";
import { NotificationItem } from "./Notifications";
import { Spinner } from "../GlobalStyles/Spinner";
import { logCustomEvent } from "../Utilities/EventConfig";
import { GiHamburgerMenu } from "react-icons/gi";
import { SideNavigation } from "./SideNavigation";
import { PromoteMusicModal } from "./PromoteMusicModal";
import "../Styles/SideNavigation.css";

export interface ToolbarProps {
  toggleSidebar: () => void;
}

export const Toolbar = ({ toggleSidebar }: ToolbarProps) => {
  const { currentUser, userType, handleLogout, setUserType } = useAuth();
  const logoUrl = "/Augio_G_Avatar_latest.png";
  const [showNotificationsDropdown, setShowNotificationsDropdown] =
    useState(false);
  const notificationDropdownRef = useRef<HTMLDivElement>(null);
  const notificationBellRef = useRef<HTMLDivElement>(null);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [NotificationRedDot, setNotificationRedDot] = useState(false);
  const lastVisibleRef = useRef<Notification | null>(null);
  const hasMoreRef = useRef(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { searchQuery, setSearchQuery } = useSearch();
  const [inputValue, setInputValue] = useState(searchQuery);
  const [showPromoModal, setShowPromoModal] = useState(false);

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const auth = getAuth();

  const staticRoutes = [
    "/home",
    "/likes",
    "/discover",
    "/top100",
    "/recentlyplayed",
    "/account",
    "/search",
  ];
  const excludedRoutes = [
    "/",
    "/login",
    "/register",
    "/settings",
    "/help",
    "/forgot-password",
    "/reset-password",
    "/terms",
    "/membership-terms",
    "/privacy-policy",
    "/copyright-infringement",
  ];
  const dynamicRoutesPatterns = [
    /^\/chart\/.+/,
    /^\/region\/.+/,
    /^\/playlist\/[^/]+$/,
  ];

  const isStaticRouteVisible = staticRoutes.includes(pathname);
  const isDynamicRouteVisible =
    dynamicRoutesPatterns.some((pattern) => pattern.test(pathname)) ||
    (/^\/[a-zA-Z0-9_-]+$/.test(pathname) && !excludedRoutes.includes(pathname));

  const isVisible = isStaticRouteVisible || isDynamicRouteVisible;

  const [showDropdown, setShowDropdown] = useState(false);
  const [showUploadSelectionModal, setShowUploadSelectionModal] =
    useState(false);
  const [showSingleForm, setShowSingleForm] = useState(false);
  const [showAlbumForm, setShowAlbumForm] = useState(false);
  const [uploadType, setUploadType] = useState<string | null>(null);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const dropdownButtonRef = useRef<HTMLButtonElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);
  const hamburgerMenuRef = useRef(null);

  const handleDropdownClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    setShowDropdown((prevState) => !prevState);
  };

  //fetch user type since it is not known on component mount
  useEffect(() => {
    let isRetryAllowed = true;
    let retryCount = 0;
    const maxRetries = 5;
    const retryDelay = 1000;

    const checkUserDocument = async () => {
      if (currentUser && isRetryAllowed && retryCount < maxRetries) {
        const userDocRef = doc(firestore, "users", currentUser.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          setUserType(userDoc.data().userType);
        } else {
          console.error("User document not found, retrying...");
          retryCount++;
          setTimeout(checkUserDocument, retryDelay * retryCount); // exponential backoff
        }
      } else if (retryCount >= maxRetries) {
        console.error("Maximum retry attempts reached, stopping retries.");
      }
    };

    checkUserDocument();

    return () => {
      isRetryAllowed = false;
    };
  }, [currentUser, setUserType]);

  // close dropdown on outside click
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        dropdownButtonRef.current &&
        !dropdownButtonRef.current.contains(event.target as Node)
      ) {
        setShowDropdown(false);
      }
    };

    if (showDropdown) {
      document.addEventListener("mousedown", handleOutsideClick);
      return () => {
        document.removeEventListener("mousedown", handleOutsideClick);
      };
    }
  }, [showDropdown]);

  // close upload selection on click outside
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        console.log("Outside Modal Click Detected");
        setShowUploadSelectionModal(false);
      }
    };

    if (showUploadSelectionModal) {
      document.addEventListener("mousedown", handleOutsideClick);
      return () =>
        document.removeEventListener("mousedown", handleOutsideClick);
    }
  }, [showUploadSelectionModal]);

  const fetchInitialNotifications = async () => {
    setIsLoading(true);
    try {
      const initialNotifications = await notificationService.fetchNotifications(
        undefined,
        20
      );
      setNotifications(initialNotifications);
      if (initialNotifications.length > 0) {
        lastVisibleRef.current =
          initialNotifications[initialNotifications.length - 1];
        hasMoreRef.current = true;
      } else {
        hasMoreRef.current = false;
      }
    } catch (error) {
      console.error("Failed to fetch initial notifications:", error);
    }
    setIsLoading(false);
  };

  const fetchNewNotifications = async () => {
    try {
      const newNotifications =
        await notificationService.fetchNewNotifications();
      console.log("Fetched new notifications:", newNotifications);
      if (newNotifications.length > 0) {
        setNotifications((prev) => [
          ...new Set([...prev, ...newNotifications]),
        ]);
        setNotificationRedDot(true);
      }
    } catch (error) {
      console.error("Failed to fetch new notifications:", error);
    }
  };

  // initial notification load and setting up new notifications check
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        await fetchInitialNotifications();
        await fetchNewNotifications();

        const intervalId = setInterval(fetchNewNotifications, 600000); // check every 10 min
        return () => clearInterval(intervalId);
      } else {
        console.log("No user is logged in.");
        setNotifications([]);
        setNotificationRedDot(false);
      }
    });

    return () => unsubscribe();
  }, [auth]);

  useEffect(() => {
    if (pathname === "/home") {
      fetchInitialNotifications();
    }
  }, [pathname]);

  const loadMoreNotifications = async () => {
    if (lastVisibleRef.current && hasMoreRef.current) {
      setIsLoading(true);
      try {
        const moreNotifications = await notificationService.fetchNotifications(
          lastVisibleRef.current,
          20
        );
        if (moreNotifications.length > 0) {
          setNotifications((prev) => [...prev, ...moreNotifications]);
          lastVisibleRef.current =
            moreNotifications[moreNotifications.length - 1];
        } else {
          hasMoreRef.current = false;
        }
        console.log("More notifications loaded:", moreNotifications);
      } catch (error) {
        console.error("Failed to load more notifications:", error);
      }
      setIsLoading(false);
    }
  };

  const handleNotificationClick = async (event: React.MouseEvent) => {
    logCustomEvent("Notifications_Click", {
      iconName: "Notification Bell",
    });
    event.stopPropagation();
    setShowNotificationsDropdown((prevState) => !prevState);

    if (!showNotificationsDropdown) {
      setIsLoading(true);
      try {
        const fetchedNotifications =
          await notificationService.fetchNotifications();
        console.log("Fetched notifications:", fetchedNotifications);
        setNotifications(fetchedNotifications);
        setNotificationRedDot(false); // hide the red dot after viewing notifications
        if (auth.currentUser) {
          await notificationService.updateUserLastChecked();
        }
      } catch (error) {
        console.error("Failed to fetch and update notifications:", error);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        notificationDropdownRef.current &&
        !notificationDropdownRef.current.contains(event.target as Node) &&
        notificationBellRef.current &&
        !notificationBellRef.current.contains(event.target as Node)
      ) {
        setShowNotificationsDropdown(false);
      }
    };

    if (showNotificationsDropdown) {
      document.addEventListener("mousedown", handleOutsideClick);
      return () => {
        document.removeEventListener("mousedown", handleOutsideClick);
      };
    }
  }, [showNotificationsDropdown]);

  const handlePromoteMusicClick = () => {
    setShowPromoModal(true);
  };

  const closePromoModal = () => {
    setShowPromoModal(false);
  };

  const handleAccountNavigation = () => {
    navigate("/account");
  };

  const handleViewProfile = async () => {
    const auth = getAuth();
    const firestore = getFirestore();
    const user = auth.currentUser;

    if (user) {
      const userDocRef = doc(firestore, "users", user.uid);
      try {
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userName = userDoc.data().userName;
          navigate(`/${userName}`);
        } else {
          console.log("User document not found.");
        }
      } catch (error) {
        console.error("Error fetching user document:", error);
      }
    } else {
      console.log("No user is currently logged in.");
    }
  };

  const handleLogoutClick = async () => {
    logCustomEvent("Logout_Click", {
      buttonName: "Logout",
    });
    await handleLogout();
    setShowDropdown(false);
    navigate("/");
  };

  const handleUploadTypeSelection = (type: string) => {
    setUploadType(type);
    setShowSingleForm(type === "single");
    setShowAlbumForm(type === "album");
    setShowUploadSelectionModal(false);
  };

  const closeUploadForm = () => {
    setShowSingleForm(false);
    setShowAlbumForm(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && inputValue.trim() !== "") {
      navigate("/search", { state: { query: inputValue } }); // navigate to search page with query as state
      setTimeout(() => {
        setSearchQuery(inputValue);
        setInputValue("");
      }, 0);
    }
  };

  if (!isVisible) return null;

  console.log("Current userType in Toolbar:", userType);

  return (
    <div className="toolbar-container">
      <Link to="/home">
        <img className="toolbar-logo" src={logoUrl} alt="logo" />
      </Link>

      <div ref={hamburgerMenuRef}>
        <GiHamburgerMenu
          className="hamburger-menu-icon"
          onClick={toggleSidebar}
        />
      </div>

      <div className="input-wrapper">
        <input
          className="search-input"
          placeholder="Search Songs"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
        />
      </div>

      <section className="toolbar-btn-container">
        {userType === "Artist" && (
          <button
            className="promote-music-btn"
            onClick={handlePromoteMusicClick}
          >
            Promote Music
          </button>
        )}

        <PromoteMusicModal
          showPromoModal={showPromoModal}
          closePromoModal={closePromoModal}
        />

        {userType === "Artist" && (
          <button
            className="upload-btn"
            onClick={() => {
              {
              }
              setShowUploadSelectionModal(true);
            }}
          >
            Upload Music
          </button>
        )}

        {showUploadSelectionModal && (
          <UploadSelectionModal
            onSelect={handleUploadTypeSelection}
            show={showUploadSelectionModal}
            ref={modalRef}
          />
        )}

        {showSingleForm && uploadType && (
          <UploadSingleForm
            show={showSingleForm}
            onClose={closeUploadForm}
            uploadType={uploadType}
          />
        )}
        {showAlbumForm && uploadType && (
          <UploadAlbumForm
            show={showAlbumForm}
            onClose={closeUploadForm}
            uploadType={uploadType}
          />
        )}

        <div className="notification-group">
          <div ref={notificationBellRef}>
            <FaBell
              className="notification-icon"
              onClick={handleNotificationClick}
            />
            {NotificationRedDot && (
              <span className="notification-red-dot"></span>
            )}
          </div>

          {showNotificationsDropdown && (
            <div
              className="notification-dropdown"
              ref={notificationDropdownRef}
              style={{ maxHeight: "300px", overflowY: "auto" }}
            >
              {isLoading ? (
                <Spinner />
              ) : (
                // Filter out mobile-exclusive notifications
                <>
                  {notifications.length > 0 ? (
                    <>
                      {notifications
                        .filter(
                          (notification) =>
                            notification.type !== "friend_request" &&
                            notification.type !== "song_recommendation"
                        )
                        .map((notification) => (
                          <NotificationItem
                            key={notification.id}
                            notification={notification}
                          />
                        ))}

                      {notifications.filter(
                        (notification) =>
                          notification.type !== "friend_request" &&
                          notification.type !== "song_recommendation"
                      ).length === 0 && (
                        <div className="no-notification-message">
                          You have no notifications
                        </div>
                      )}

                      {notifications.filter(
                        (notification) =>
                          notification.type !== "friend_request" &&
                          notification.type !== "song_recommendation"
                      ).length > 0 &&
                        hasMoreRef.current && (
                          <button
                            onClick={loadMoreNotifications}
                            disabled={isLoading}
                            className="load-more-notifications-btn"
                          >
                            Load More
                          </button>
                        )}
                    </>
                  ) : (
                    <div className="no-notification-message">
                      You have no notifications
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </div>

        <button
          className="account-settings-btn"
          ref={dropdownButtonRef}
          onClick={handleDropdownClick}
        >
          Account
        </button>
        {showDropdown && (
          <div className="account-dropdown" ref={dropdownRef}>
            <div onClick={handleAccountNavigation}>Account</div>
            {userType === "Artist" && (
              <div onClick={handleViewProfile}>View Profile</div>
            )}
            <div onClick={handleLogoutClick}>Logout</div>
          </div>
        )}
      </section>
    </div>
  );
};
