import React from 'react';
import './Spinner.css'; 

export const Spinner = () => {
    return (
        <div className='overlay'>
            <div className='spinner'></div>
        </div>
    );
}

