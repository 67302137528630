import React, { useState, useContext } from "react";
import "../Styles/Playlists.css";
import { SideNavigationContext } from "../Context/SideNavigationContext";
import { PlaylistService } from "../Services/PlaylistService";
import { useAuth } from "../Context/AuthContext";

type PlaylistModalProps = {
  onClose: () => void;
  mode: "create" | "edit" | "delete";
  playlistId?: string;
  initialName?: string;
};

export function PlaylistModal({
  onClose,
  mode,
  playlistId,
  initialName = "",
}: PlaylistModalProps) {
  const { currentUser } = useAuth();
  const [playlistName, setPlaylistName] = useState(initialName);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const context = useContext(SideNavigationContext);

  if (!context) {
    throw new Error(
      "PlaylistModal must be used within a SideNavigationProvider"
    );
  }

  const { addPlaylist, removePlaylist, editPlaylist } = context;

  
const handleSave = async () => {
  try {
    if (currentUser) {
      const userId = currentUser.uid;

      if (mode === "create") {
        const newPlaylistId = await PlaylistService.createPlaylist(
          userId,
          playlistName 
        );
        addPlaylist({ id: newPlaylistId, playlistName });
      } else if (mode === "edit" && playlistId) {
        await PlaylistService.editPlaylist(
          playlistId,
          playlistName 
        );
        editPlaylist(playlistId, playlistName);
      }

      onClose();
    } else {
      console.error("No user is signed in.");
    }
  } catch (error) {
    console.error("Error saving playlist:", error);
  }
};






  const handleDelete = async () => {
    try {
      if (playlistId) {
        await PlaylistService.deletePlaylist(playlistId);
        removePlaylist(playlistId);
        onClose();
      }
    } catch (error) {
      console.error("Error deleting playlist:", error);
    }
  };

  const renderContent = () => {
    if (confirmDelete) {
      return (
        <>
          <p className="playlist-header">
            Are you sure you want to delete "{playlistName}"?
          </p>
          <div className="modal-actions">
            <button className="delete-playlist-btn" onClick={handleDelete}>
              Delete
            </button>
            <button className="cancel-playlist-btn" onClick={onClose}>
              Cancel
            </button>
          </div>
        </>
      );
    } else {
      return (
        <>
          <p className="playlist-header">
            {mode === "edit" ? "Edit Playlist" : "Create Playlist"}
          </p>
          <input
            type="text"
            value={playlistName}
            onChange={(e) => setPlaylistName(e.target.value)}
            className="playlist-name-input"
          />
          <button className="save-playlist-btn" onClick={handleSave}>
            {mode === "edit" ? "Update" : "Save"}
          </button>
          {mode === "edit" && (
            <button
              className="delete-playlist-btn"
              onClick={() => setConfirmDelete(true)}
            >
              Delete
            </button>
          )}
        </>
      );
    }
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div
        className="playlist-details-box"
        onClick={(e) => e.stopPropagation()}
      >
        {renderContent()}
      </div>
    </div>
  );
}
