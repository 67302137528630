import React from 'react';
import './ProgressSpinner.css';

interface SpinnerProps {
  percentage: number;
}

export const ProgressSpinner = ({ percentage }: SpinnerProps) => {
    return (
        <div className="progress-spinner-container">
            <div className="progress-spinner"></div>
        </div>
    );
}
