import React, { useState, useEffect } from "react";
import "../Styles/Playlists.css";
import { PlaylistService } from "../Services/PlaylistService";
import { useParams } from "react-router-dom";
import { AudioTrack } from "./AudioTrack";
import { Track } from "../Types/Types";

export const Playlist = () => {
  const { playlistUrlName, playlistId } = useParams<{
    playlistUrlName: string;
    playlistId: string;
  }>();

  const [tracks, setTracks] = useState<Track[]>([]);
  const [playlistName, setPlaylistName] = useState<string>("");

  console.log("Component rendered");

  useEffect(() => {
    console.log("Loading playlist for URL name:", playlistUrlName);
    const loadPlaylistData = async () => {
      if (!playlistUrlName) {
        console.error("Playlist URL name is required");
        return;
      }

      console.log("Fetching playlist by URL name:", playlistUrlName);

      try {
        const fetchedPlaylist = await PlaylistService.fetchPlaylistByUrlName(
          playlistUrlName
        );

        if (!fetchedPlaylist) {
          console.error("Playlist not found");
          return;
        }

        console.log("Fetched Playlist:", fetchedPlaylist);
        setPlaylistName(fetchedPlaylist.playlistName);

        const trackIds = await PlaylistService.fetchPlaylistTracks(
          fetchedPlaylist.id
        );
        const tracksDetails = await PlaylistService.fetchTracksDetails(
          trackIds
        );
        setTracks(tracksDetails);
      } catch (error) {
        console.error("Error loading playlist:", error);
      }
    };

    loadPlaylistData();
  }, [playlistUrlName]);

  return (
    <div className="page-container">
      <section className="inner-page-layout">
        <section className="inner-page-content">
          <h1 className="header-title">Playlist - {playlistName}</h1>
          <section
            className={`feed-tracks-container ${
              tracks.length > 0 ? "not-empty" : "empty"
            }`}
          >
            {tracks.length > 0 ? (
              tracks.map((track) => (
                <AudioTrack
                  key={track.id}
                  track={track}
                  feedIdentifier="playlist"
                  feedTracks={tracks}
                />
              ))
            ) : (
              <p className="playlist-message">No tracks found</p>
            )}
          </section>
        </section>
      </section>
    </div>
  );
};
