import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { analytics } from '../firebase'
import { logEvent } from 'firebase/analytics';


//for general events like button clicks etc
export const logCustomEvent = (eventName: string, eventParams?: { [key: string]: any }) => {
  if (window.location.hostname !== "localhost" && analytics) {
    try {
      logEvent(analytics, eventName, eventParams);
    } catch (error) {
      console.error(`Error logging event '${eventName}':`, error);
    }
  } else {
    console.log("Analytics tracking is disabled in local environment, event not logged:", eventName);
  }
};

//for page view events
export const useTrackPageViews = () => {
  const location = useLocation();

  useEffect(() => {
    // check if we are not running locally
    if (window.location.hostname !== "localhost" && analytics) {
      // Proceed to log the page view
      logEvent(analytics, 'page_view', {
        page_path: location.pathname,
        page_title: document.title,
        page_location: window.location.href,
      });
    } else {
      // log to console or do nothing in local environment
      console.log("Analytics tracking is disabled in local environment.");
    }
  }, [location]); 
}
