import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { Toolbar } from "./Toolbar";
import { SideNavigation } from "./SideNavigation";
import { AudioTrack } from "./AudioTrack";
import { AudioPlayer } from "./AudioPlayer";
import "../Styles/Profile.css";
import { FaUserCircle } from "react-icons/fa";
import { ProfileService } from "../Services/ProfileService";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { FeedService } from "../Services/FeedService";
import { Track } from "../Types/Types";
import { AudioPlayerContext } from "../Context/AudioPlayerContext";

export const Profile = () => {
  const coverArt = "/weeknd_cover.png";
  const { userName } = useParams<{ userName?: string }>();
  console.log(`Current userName: ${userName}`);

  const [tracks, setTracks] = useState<Track[]>([]);
  const [filter, setFilter] = useState<string>("recent");
  const [artistName, setArtistName] = useState("");
  const [avatarImage, setAvatarImage] = useState("");
  const [regionName, setRegionName] = useState("");
  const [isFollowing, setIsFollowing] = useState(false);
  const [fansCount, setFansCount] = useState(0);
  const [currentUserID, setCurrentUserID] = useState<string | null>(null);
  const [loadAll, setLoadAll] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUserID(user.uid);
      } else {
        setCurrentUserID(null);
      }
    });
    return () => unsubscribe();
  }, []);


  useEffect(() => {
    console.log(
      `Fetching tracks for userName: ${userName} with filter: ${filter}`
    );
    const limit = loadAll ? undefined : 10; 
    if (userName) {
      ProfileService.getTracksByUser(userName, filter, limit)
        .then((fetchedTracks) => {
          setTracks(fetchedTracks);
        })
        .catch(console.error);
    }
  }, [userName, filter, loadAll]);

  useEffect(() => {
    if (userName) {
      console.log(`Fetching user details for userName: ${userName}`);
      ProfileService.getUserDetails(userName)
        .then((userDetails) => {
          setArtistName(userDetails.artistName);
          setFansCount(userDetails.fans);
          setAvatarImage(userDetails.avatarUrl);
          return ProfileService.getRegionName(userDetails.regionID);
        })
        .then((fetchedRegionName) => {
          setRegionName(fetchedRegionName);
        })
        .catch(console.error);
    }
  }, [userName]);

  useEffect(() => {
    if (currentUserID && userName) {
      const checkFollowStatus = async () => {
        const followedUserId = await FeedService.getUserIdByUsername(userName);
        if (followedUserId) {
          const isUserFollowing = await FeedService.isFollowing(
            currentUserID,
            followedUserId
          );
          setIsFollowing(isUserFollowing);
        } else {
          console.error("User not found for username:", userName);
        }
      };

      checkFollowStatus();
    }
  }, [userName, currentUserID]);

  const handleFilterChange = (newFilter: string) => {
    setFilter(newFilter);
  };

  const getButtonClass = (buttonFilter: string) =>
    `profile-filter-btn ${filter === buttonFilter ? "selected" : ""}`;

  const toggleFollowStatus = async () => {
    if (!currentUserID || !userName) return; 
  
    const followedUserId = await FeedService.getUserIdByUsername(userName);
  
    if (!followedUserId) {
      console.error("User not found for username:", userName);
      return;
    }
  
    const previousFollowStatus = isFollowing;
    setIsFollowing(!isFollowing);
  
    try {
      if (previousFollowStatus) {
        await FeedService.unfollowUser(currentUserID, followedUserId);
      } else {
        await FeedService.followUser(currentUserID, followedUserId);
      }
    } catch (error) {
      console.error("Failed to toggle follow status:", error);
      setIsFollowing(previousFollowStatus);
    }
  };

  const handleLoadAll = () => {
    setLoadAll(true);
  };

  return (
    <div className="page-container">
      <section className="inner-page-layout">
        <section className="inner-page-content">
          <section className="profile-banner">
            {avatarImage ? (
              <img
                className="profile-artist-avatar"
                src={avatarImage}
                alt="Artist"
              />
            ) : (
              <FaUserCircle className="profile-artist-avatar avatar-placeholder" />
            )}
            <section className="profile-details">
              <p className="profile-artist-name">{artistName}</p>
              <p className="profile-artist-region">{regionName}</p>
              <p className="profile-artist-fans">
                {new Intl.NumberFormat().format(fansCount)} Fans
              </p>
            </section>
            <button className="profile-follow-btn" onClick={toggleFollowStatus}>
              {isFollowing ? "Following" : "Follow"}
            </button>
          </section>
          <section className="profile-filter-btn-container">
            <button
              className={getButtonClass("recent")}
              onClick={() => handleFilterChange("recent")}
            >
              Recent
            </button>
            <button
              className={getButtonClass("popular")}
              onClick={() => handleFilterChange("popular")}
            >
              Popular
            </button>
            <button
              className={getButtonClass("singles")}
              onClick={() => handleFilterChange("singles")}
            >
              Singles
            </button>
            <button
              className={getButtonClass("albums")}
              onClick={() => handleFilterChange("albums")}
            >
              Albums/EPs
            </button>
          </section>
          <section>
            {tracks.map((track) => (
              <AudioTrack
                key={track.id}
                track={track}
                feedIdentifier="profile"
                feedTracks={tracks}
              />
            ))}
            {tracks.length >= 10 && !loadAll && (
              <button className='profile-load-more-btn' onClick={handleLoadAll}>
                Load All
              </button>
            )}
          </section>
        </section>
      </section>
    </div>
  );
};
