import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import "../Styles/Register.css";
import { RegisterService } from "../Services/RegisterService";
import { UserType } from "../Utilities/UserTypes";
import { Spinner } from "../GlobalStyles/Spinner";
import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase";
import { RegisterFormData, State, Region } from "../Types/Types";
import {
  validateName,
  validateEmail,
  validateUserName,
  validateUserType,
  validateState,
  validatePassword,
  validateArtistName,
  validateTermsCheckBox,
} from "../Services/RegisterValidation";
import { logCustomEvent } from "../Utilities/EventConfig";
import { sortedStates } from "../Utilities/StaticFormData";

export const Register = () => {
  const logoImage: string = "/Augio_FullType_Logo_latest.png";
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [regions, setRegions] = useState<Region[]>([]);
  const [showRegionDropdown, setShowRegionDropdown] = useState(false);
  const [regionImage, setRegionImage] = useState<string | null>(null);

  const [formData, setFormData] = useState<RegisterFormData>({
    name: "",
    email: "",
    password: "",
    userType: "",
    userName: "",
    artistName: "",
    state: "",
    region: "",
    terms: false,
  });

  const [errors, setErrors] = useState({
    userType: "",
    state: "",
    name: "",
    email: "",
    userName: "",
    artistName: "",
    password: "",
    terms: "",
    region: "",
  });

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    console.log(`Input Change - ${name}:`, value);

    setFormData((prevState) => ({ ...prevState, [name]: value }));

    if (name === "state") {
      setShowRegionDropdown(false);
      setRegions([]);
      setRegionImage(null);
      setFormData((prevState) => ({
        ...prevState,
        region: "",
      }));
    } else if (name === "region") {
      const selectedRegion = regions.find(
        (region) => region.regionName === value
      );
      if (selectedRegion) {
        setRegionImage(selectedRegion.regionImage);
      }
    }
  };

  useEffect(() => {
    const fetchRegions = async () => {
      if (formData.state) {
        const selectedState = sortedStates.find(
          (s) => s.stateName === formData.state
        );
        if (selectedState) {
          const regionsList = await RegisterService.fetchRegionsByState(
            selectedState.stateID
          );
          setRegions(regionsList);
          setShowRegionDropdown(regionsList.length > 0);

          setRegionImage(null);
        }
      }
    };

    fetchRegions();
  }, [formData.state]);

  useEffect(() => {
    const fetchRegions = async () => {
      if (formData.state) {
        const selectedState = sortedStates.find(
          (s) => s.stateName === formData.state
        );
        if (selectedState) {
          const regionsList = await RegisterService.fetchRegionsByState(
            selectedState.stateID
          );
          setRegions(regionsList);
          setShowRegionDropdown(regionsList.length > 0);

          if (regionsList.length > 0) {
            setRegionImage(regionsList[0].regionImage);
          }
        }
      }
    };

    fetchRegions();
  }, [formData.state]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      terms: checked,
    }));

    const termsError = validateTermsCheckBox(checked);
    setErrors((prevErrors) => ({
      ...prevErrors,
      terms: termsError,
    }));
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);

    const nameError = validateName(formData.name);
    const emailError = await validateEmail(formData.email);
    const userNameError =
      formData.userType === UserType.Listener
        ? await validateUserName(formData.userName || "")
        : "";
    const artistNameError =
      formData.userType === UserType.Artist
        ? await validateArtistName(formData.artistName || "")
        : "";
    const userTypeError = validateUserType(formData.userType);
    const stateError = validateState(formData.state);
    const passwordError = validatePassword(formData.password);
    const termsError = validateTermsCheckBox(formData.terms);
    const regionError =
      showRegionDropdown && !formData.region ? "Region is required" : "";

    const newErrors = {
      name: nameError,
      email: emailError,
      userName: userNameError,
      artistName: artistNameError,
      userType: userTypeError,
      state: stateError,
      password: passwordError,
      terms: termsError,
      region: regionError,
    };

    setErrors(newErrors);

    if (Object.values(newErrors).some((error) => error !== "")) {
      setIsLoading(false);
      return;
    }

    try {
      await RegisterService.registerUser(formData);
      logCustomEvent("Register Event");
      navigate("/home");
    } catch (error) {
      if (
        error instanceof Error &&
        (error as any).code === "auth/email-already-in-use"
      ) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: "Email already in use",
        }));
      } else {
        console.error("Registration failed:", error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="create-account-container">
      <form className="create-account-form" onSubmit={handleFormSubmit}>
        {isLoading && <Spinner />}
        <Link className="create-account-logo" to="/">
          <img src={logoImage} alt="Logo" className="create-account-logo" />
        </Link>
        <h3 className="signup-text">Sign Up for Your Free Account</h3>
        {/* User Type */}
        <label className="create-account-form-label">
          User Type<span style={{ color: "#D42616" }}>*</span>
        </label>
        <select
          name="userType"
          className="create-account-input dropdown-input"
          value={formData.userType}
          onChange={handleInputChange}
        >
          <option value="">User Type</option>
          <option value={UserType.Artist}>Artist</option>
          <option value={UserType.Listener}>Listener</option>
        </select>
        {errors.userType && (
          <span className="error-message">{errors.userType}</span>
        )}

        <select
          name="state"
          className="create-account-input dropdown-input"
          value={formData.state}
          onChange={handleInputChange}
        >
          <option value="">State</option>
          {sortedStates.map((state) => (
            <option key={state.stateID} value={state.stateName}>
              {state.stateName}
            </option>
          ))}
        </select>
        {errors.state && <span className="error-message">{errors.state}</span>}

        {regions.length > 0 && (
          <>
            <label className="create-account-form-label">
              Region<span style={{ color: "#D42616" }}>*</span>
            </label>
            <select
              name="region"
              className="create-account-input dropdown-input"
              value={formData.region}
              onChange={handleInputChange}
            >
              <option value="">Select Region</option>
              {regions.map((region) => (
                <option key={region.regionID} value={region.regionName}>
                  {region.regionName}
                </option>
              ))}
            </select>
            {errors.region && (
              <span className="error-message">{errors.region}</span>
            )}
          </>
        )}

        <div className="regions-display">
          {formData.region && regionImage ? (
            <div className="region-container">
              <div className="region-image-container">
                <img
                  src={regionImage}
                  alt={`Region: ${formData.region}`}
                  className="region-image-register-form"
                />
              </div>
              <p className="region-name-register-form">{formData.region}</p>
            </div>
          ) : (
            regions.map((region) => (
              <div className="region-container" key={region.regionID}>
                <div className="region-image-container">
                  <img
                    src={region.regionImage}
                    alt={`Region: ${region.regionName}`}
                    className="region-image-register-form"
                  />
                </div>
                <p className="region-name-register-form">{region.regionName}</p>
              </div>
            ))
          )}
        </div>

        <div className="form-input-row">
          <div className="form-input-group">
            <label className="create-account-form-label">
              Name<span style={{ color: "#D42616" }}>*</span>
            </label>
            <input
              name="name"
              className="create-account-input"
              value={formData.name}
              onChange={handleInputChange}
            />
            {errors.name && (
              <span className="error-message">{errors.name}</span>
            )}
          </div>

          <div className="form-input-group">
            <label className="create-account-form-label">
              Email<span style={{ color: "#D42616" }}>*</span>
            </label>
            <input
              name="email"
              className="create-account-input"
              value={formData.email}
              onChange={handleInputChange}
            />
            {errors.email && (
              <span className="error-message">{errors.email}</span>
            )}
          </div>
        </div>
        <div className="form-input-row">
          {formData.userType === UserType.Artist && (
            <div className="form-input-group">
              <label className="create-account-form-label">
                Artist Name<span style={{ color: "#D42616" }}>*</span>
              </label>
              <input
                name="artistName"
                className="create-account-input"
                value={formData.artistName}
                onChange={handleInputChange}
              />
              {errors.artistName && (
                <span className="error-message">{errors.artistName}</span>
              )}
            </div>
          )}

          {formData.userType === UserType.Listener && (
            <div className="form-input-group">
              <label className="create-account-form-label">
                Username<span style={{ color: "#D42616" }}>*</span>
              </label>
              <input
                name="userName"
                className="create-account-input"
                value={formData.userName}
                onChange={handleInputChange}
              />
              {errors.userName && (
                <span className="error-message">{errors.userName}</span>
              )}
            </div>
          )}

          <div className="form-input-group">
            <label className="create-account-form-label">
              Password<span style={{ color: "#D42616" }}>*</span>
            </label>
            <input
              name="password"
              type="password"
              className="create-account-input"
              value={formData.password}
              onChange={handleInputChange}
            />
            {errors.password && (
              <span className="error-message">{errors.password}</span>
            )}
          </div>
        </div>
        <div className="form-input-row">
          <div className="form-input-group">
            <label className="create-account-form-label">
              <input
                type="checkbox"
                checked={formData.terms}
                onChange={handleCheckboxChange}
                className="checkbox"
              />
              Agree to Terms
            </label>
            {errors.terms && (
              <span className="error-message">{errors.terms}</span>
            )}
            <p className="register-terms-agreement-text">
              By checking this box you acknowledge and agree to the Augio&nbsp;
              <Link className="agree-terms-link" target="_blank" to="/terms">
                Terms and Conditions
              </Link>
              &nbsp;and&nbsp;
              <Link
                className="agree-terms-link"
                target="_blank"
                to="/membership-terms"
              >
                Member &amp; Distribution Agreement
              </Link>
            </p>
          </div>
        </div>
        {/* Submit Button */}
        <button type="submit" className="create-account-submit">
          Create Account
        </button>
      </form>
    </div>
  );
};
