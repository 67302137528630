import { firestore } from '../firebase'
import { query, collection, where, getDocs,
  doc, getDoc, setDoc, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore';

export class ChartService {

  // utility function to capitalize the first letter
  static capitalizeFirstLetter(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  static async getStateNameByAlias(stateNameAlias: string): Promise<string> {
    // normalize the stateNameAlias to match Firestore data
    const normalizedAlias = this.capitalizeFirstLetter(stateNameAlias);
    
    console.log("Querying for normalized stateNameAlias:", normalizedAlias); 
    const q = query(collection(firestore, 'stateCharts'), where('stateNameAlias', '==', normalizedAlias));

    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const docSnapshot = querySnapshot.docs[0];
      const data = docSnapshot.data();
      console.log("Document Data:", data); 
      return data.stateName;
    } else {
      console.error('No document found for alias:', normalizedAlias); 
      throw new Error('State not found');
    }
  }

  static async getChartIdByStateNameAlias(stateNameAlias: string): Promise<string> {
    // normalize the stateNameAlias to match Firestore data
    const normalizedAlias = this.capitalizeFirstLetter(stateNameAlias);
    
    const q = query(collection(firestore, 'stateCharts'), where('stateNameAlias', '==', normalizedAlias));

    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      return querySnapshot.docs[0].id; 
    } else {
      throw new Error('Chart not found');
    }
  }

  static async toggleFavoriteChart(userId: string, chartId: string, isFavorited: boolean): Promise<void> {
    const favoriteChartsDocRef = doc(firestore, 'favoriteCharts', userId);
    const docSnapshot = await getDoc(favoriteChartsDocRef);
    if (!docSnapshot.exists()) {
      await setDoc(favoriteChartsDocRef, {
        chartIDs: isFavorited ? [chartId] : [],
        userId: userId  
      }, { merge: true });
    } else {
      if (isFavorited) {
        await updateDoc(favoriteChartsDocRef, {
          chartIDs: arrayUnion(chartId),
          userId: userId 
        });
      } else {
        await updateDoc(favoriteChartsDocRef, {
          chartIDs: arrayRemove(chartId),
          userId: userId  
        });
      }
    }
  
    const stateChartDocRef = doc(firestore, 'stateCharts', chartId);
    const stateChartDoc = await getDoc(stateChartDocRef);
    if (stateChartDoc.exists()) {
      const currentFansCount = stateChartDoc.data().chartFans || 0;
      const updatedFansCount = isFavorited ? currentFansCount + 1 : currentFansCount - 1;
      await updateDoc(stateChartDocRef, {
        chartFans: updatedFansCount
      });
    }
  }
  

  static async getFansCountByChartId(chartId: string): Promise<number> {
    const docRef = doc(firestore, 'stateCharts', chartId);
    const docSnapshot = await getDoc(docRef);
    if (docSnapshot.exists()) {
      const data = docSnapshot.data();
      return data.chartFans; 
    } else {
      throw new Error('Chart not found');
    }
  }
  

  static async fetchFavoriteCharts(userId: string): Promise<any[]> {
    const favoriteChartsDocRef = doc(firestore, 'favoriteCharts', userId);
    const docSnapshot = await getDoc(favoriteChartsDocRef);
  
    if (docSnapshot.exists()) {
      const data = docSnapshot.data();
      const chartIDs = data.chartIDs as number[] || []; 
      const chartDetails = await Promise.all(
        chartIDs.map(async (chartId: number) => {
          const stateChartDocRef = doc(firestore, 'stateCharts', chartId.toString());
          const stateChartSnapshot = await getDoc(stateChartDocRef);
          if (stateChartSnapshot.exists()) {
            const chartData = stateChartSnapshot.data();
            return {
              id: chartId.toString(), 
              name: chartData.stateName,
              stateNameAlias: chartData.stateNameAlias
            };
          } else {
            return { id: chartId.toString(), name: 'Unknown Chart', stateNameAlias: '' };
          }
        })
      );
      console.log("Favorite charts data:", chartDetails);
      return chartDetails;
    } else {
      return [];
    }
  }
  
  


}
