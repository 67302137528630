import React, { useContext, useEffect, useRef, useState } from "react";
import "../Styles/AudioPlayer.css";
import {
  IoPlayCircleSharp,
  IoPlaySkipBackCircle,
  IoPlaySkipForwardCircle,
} from "react-icons/io5";
import { FaPauseCircle } from "react-icons/fa";
import { TbRepeat } from "react-icons/tb";
import { ImShuffle } from "react-icons/im";
import { VolumeSlider } from "../GlobalStyles/VolumeSlider";
import { useLocation } from "react-router-dom";
import { AudioPlayerContext } from "../Context/AudioPlayerContext";

export const AudioPlayer = () => {
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const playNexticon = "/play_next_icon.svg"
  const playPreviousIcon = "/play_previous_icon.svg"
  const playIcon = "/play_icon.svg"
  const pauseIcon = "/pause_icon.svg"
  const { pathname } = useLocation();

  const staticRoutes = [
    "/home",
    "/account",
    "/likes",
    "/discover",
    "/top100",
    "/recentlyplayed",
    "/search",
  ];
  const excludedRoutes = [
    "/",
    "/login",
    "/register",
    "/settings",
    "/help",
    "/forgot-password",
    "/reset-password",
    "/terms",
    "/membership-terms",
    "/privacy-policy",
    "/copyright-infringement"
  ];

  const dynamicRoutesPatterns = [
    /^\/chart\/.+/,
    /^\/region\/.+/,
    /^\/playlist\/[^/]+$/,
  ];

  // visibility based on the current route
  const isStaticRouteVisible = staticRoutes.includes(pathname);
  const isDynamicRouteVisible =
    dynamicRoutesPatterns.some((pattern) => pattern.test(pathname)) ||
    (/^\/[a-zA-Z0-9_-]+$/.test(pathname) && !excludedRoutes.includes(pathname));

  const isVisible = isStaticRouteVisible || isDynamicRouteVisible;

  const {
    currentTrack,
    isPlaying,
    volume,
    playNext,
    playPrevious,
    togglePlayPause,
    adjustVolume,
    currentTime,
    duration,
    setCurrentTime,
    setDuration,
    tempTime,
    setTempTime,
    setPlaylist,
    setCurrentDataSource, 
    playTrackById,
    toggleRepeat,
    toggleShuffle,
    isRepeating,
    isShuffling,
  } = useContext(AudioPlayerContext);

  const audioRef = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    const audio = audioRef.current;
    if (!audio || !currentTrack) return;

    const playAudio = () => {
      if (isPlaying) {
        audio.play().catch((error) =>
          console.error("Error attempting to play audio:", error));
      }
    };

    const handleCanPlay = () => {
      if (currentTime > 0 && audio.currentTime !== currentTime) {
        audio.currentTime = currentTime;
      }
      playAudio();
    };

    const needsSourceUpdate = audio.src !== new URL(currentTrack.audioFileUrl, window.location.href).href;
    if (needsSourceUpdate) {
      audio.src = currentTrack.audioFileUrl;
      audio.load(); 
      audio.addEventListener('canplay', handleCanPlay);
    } else if (isPlaying) {
      playAudio();  
    } else {
      audio.pause();
    }

    return () => {
      audio.removeEventListener('canplay', handleCanPlay);
    };
}, [currentTrack, isPlaying]);

  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      audio.volume = volume / 100;
    }
  }, [volume]);

  const handleVolumeChange = (newVolume: number) => {
    adjustVolume(newVolume);
  };


  if (!isVisible) return null;

  const defaultCoverArtUrl = "/no_song_selected_placeholder.jpg";
  const defaultTrackTitle = "No song selected";
  const defaultUserName = "- -";

  function formatTime(seconds: number) {
    let minute = Math.floor(seconds / 60);
    let secondLeft = Math.floor(seconds - minute * 60);
    return `${minute}:${secondLeft < 10 ? "0" : ""}${secondLeft}`;
  }

const handleProgressBarClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
  const progressBar = event.currentTarget; 
  const progressBarRect = progressBar.getBoundingClientRect();
  const clickX = event.clientX - progressBarRect.left; 
  const newTime = (clickX / progressBarRect.width) * duration; 

  if (audioRef.current) {
    audioRef.current.currentTime = newTime; 
  }
};

const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
  handleProgressBarClick(event); 
};

  return (
    <div className="audioplayer-container">
      <div className="audioplayer-song-details-container">
        <img
          className="audioplayer-song-cover"
          src={currentTrack ? currentTrack.coverArtUrl : defaultCoverArtUrl}
          alt={currentTrack ? currentTrack.trackTitle : defaultTrackTitle}
        />
        <div className="audioplayer-song-details">
          <p className="audioplayer-song">
            {currentTrack ? currentTrack.trackTitle : defaultTrackTitle}
          </p>
          <p className="audioplayer-artist">
            {currentTrack ? currentTrack.artistName : defaultUserName}
          </p>
        </div>
      </div>
      <div className="audioplayer-controls-container">
        <div className="audioplayer-controls">
        <ImShuffle onClick={toggleShuffle} className={`audio-control-shuffle ${isShuffling ? 'active' : ''}`}/>
          <img src={playPreviousIcon}
            className="audio-control"
            onClick={playPrevious}
          />
          {isPlaying ? (
            <img src={pauseIcon}
              className="audio-control-pause"
              onClick={togglePlayPause}
            />
          ) : (
            <img src={playIcon}
              className="audio-control-play"
              onClick={togglePlayPause}
            />
          )}
          <img src={playNexticon}
            className="audio-control"
            onClick={playNext}
          />
          <TbRepeat onClick={toggleRepeat} className={`audio-control-repeat ${isRepeating ? 'active' : ''}`}/>
        </div>
        <div className="audioplayer-progress-slider">
          <div className='audioplayer-time'>{formatTime(currentTime)}</div>

          <div
            className="audioplayer-progress-bar"
            onMouseDown={handleMouseDown} 
          >
            <div
              className="audioplayer-progress-fill"
              style={{ width: `${(currentTime / duration) * 100}%` }}
            ></div>
          </div>

          <div className='audioplayer-time'>{formatTime(duration)}</div>
        </div>
      </div>
      <VolumeSlider volume={volume} onVolumeChange={handleVolumeChange} />
      <audio
        ref={audioRef}
        onEnded={playNext}
        onTimeUpdate={() =>
          setCurrentTime(audioRef.current ? audioRef.current.currentTime : 0)
        }
        onLoadedMetadata={() =>
          setDuration(audioRef.current ? audioRef.current.duration : 0)
        }
      />
    </div>
  );
};
