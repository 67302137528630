import React, { useEffect, useState, useContext } from 'react';
import { getRecentlyPlayedTracks } from '../Services/RecentlyPlayedService';
import { AudioTrack } from './AudioTrack';
import { Track } from '../Types/Types'; 
import { auth } from "../firebase";
import { AudioPlayerContext } from '../Context/AudioPlayerContext'; 


export const RecentlyPlayed = () => {
  const [playedTracks, setPlayedTracks] = useState<Track[]>([]);
  

  useEffect(() => {
    const fetchRecentlyPlayed = async () => {
      const userId = auth.currentUser ? auth.currentUser.uid : null;

      if (!userId) return;

      const tracks = await getRecentlyPlayedTracks(userId);

      const uniqueTracks = tracks.filter((track, index, self) =>
        index === self.findIndex((t) => t.id === track.id)
      );

      setPlayedTracks(uniqueTracks);
    };

    fetchRecentlyPlayed().catch(console.error);
  }, []); 



  return (
    <div className="page-container">
      <section className="inner-page-layout">    
        <section className="inner-page-content">
          <h1 className='header-title'>Recently Played</h1>

          <section>
            {playedTracks.map((track) => (
              <AudioTrack key={track.id} track={track} showChartPosition={false} feedIdentifier="recentlyPlayed" feedTracks={playedTracks}/>
            ))}
          </section>
          
        </section>
      </section>
    </div>
  );
};
