import { firestore } from "../firebase"; 
import { doc, getDoc, collection, query, where, getDocs, addDoc, runTransaction, Timestamp, orderBy, limit } from 'firebase/firestore';
import type { Track } from "../Types/Types";

export const addTrackToRecentlyPlayed = async (userId: string, track: Track) => {
  const recentlyPlayedRef = collection(firestore, 'recentlyPlayed');

  const q = query(recentlyPlayedRef, where('userId', '==', userId));

  const querySnapshot = await getDocs(q);

  const trackDetails = {
    trackId: track.id,
    playedAt: Timestamp.now(),
  };

  if (!querySnapshot.empty) {
    const docRef = querySnapshot.docs[0].ref;

    await runTransaction(firestore, async (transaction) => {
      const docSnapshot = await transaction.get(docRef);
      if (!docSnapshot.exists()) {
        throw "Document does not exist!";
      }

      let tracks = docSnapshot.data().tracks;

      // filter out duplicate trackIds
      tracks = tracks.filter((t: { trackId: string }) => t.trackId !== trackDetails.trackId);

      // add new track to the beginning and keep only the latest 30 tracks
      tracks = [trackDetails, ...tracks.slice(0, 29)];

      transaction.update(docRef, { tracks });
    });

  } else {
    await addDoc(recentlyPlayedRef, {
      userId: userId,
      tracks: [trackDetails], 
    });
  }
};

export default addTrackToRecentlyPlayed;


const getRecentlyPlayedTracks = async (userId: string) => {
    const recentlyPlayedRef = collection(firestore, 'recentlyPlayed');
    const q = query(recentlyPlayedRef, where('userId', '==', userId), limit(1)); 
  
    const querySnapshot = await getDocs(q);
    let trackIds: string[] = [];
    querySnapshot.forEach((doc) => {
      const docData = doc.data();
      if (docData.tracks) {
        trackIds = docData.tracks.map((track: { trackId: string }) => track.trackId);
      }
    });
  
    const trackDetailsPromises: Promise<Track | null>[] = trackIds.map(trackId => fetchTrackDetails(trackId));
    const trackDetails = await Promise.all(trackDetailsPromises);
  
    const filteredTrackDetails = trackDetails.filter(track => track !== null) as Track[];
  
    console.log(filteredTrackDetails);
    return filteredTrackDetails; 
};

export { getRecentlyPlayedTracks };

  

async function fetchTrackDetails(trackId: string): Promise<Track | null> {
    const trackRef = doc(firestore, 'tracks', trackId);
    const trackSnap = await getDoc(trackRef);
    if (trackSnap.exists()) {
        const { id, ...trackData } = trackSnap.data() as Track; 
        const trackDetails: Track = { id: trackSnap.id, ...trackData }; 
        return trackDetails;
    } else {
        console.error('No track found for ID:', trackId);
        return null;
    }
}


  
  
  