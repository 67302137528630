import { Link, useNavigate } from "react-router-dom";
import "../Styles/Landing.css";
import { useEffect, useState, useRef } from "react";
import { BsTwitter } from "react-icons/bs";
import { HiOutlineMail } from "react-icons/hi";
import { AiFillInstagram } from "react-icons/ai";
import { FaTiktok } from "react-icons/fa";
import { MdArrowForwardIos, MdOutlineContactSupport } from "react-icons/md";
import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase";
import { collection, addDoc } from "firebase/firestore";
import { firestore } from "../firebase";
import { useAuth } from "../Context/AuthContext";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
import { MdOutlineArticle } from "react-icons/md";
import { logCustomEvent } from "../Utilities/EventConfig";

export const LandingPage = () => {
  const logoUrl: string = "/Augio_FullType_Logo_latest.png";
  const gLogoUrl: string = "/Augio_G_Avatar_latest.png";
  const mapImage: string = "/color_map_edit_2.png";
  const iOSAppImage: string = "/io_app_preview_01.png";
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [userData, setUserData] = useState("");
  const [resValue, setResValue] = useState(false);
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState("");
  const [submitError, setSubmitError] = useState("");
  const [pageVisible, setPageVisible] = useState(false);
  const [fadeSuccess, setFadeSuccess] = useState(false);
  const [animateText, setAnimateText] = useState(false);

  useEffect(() => {
    if (currentUser) {
      navigate("/home");
    }
  }, [currentUser, navigate]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimateText(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) {
        setIsVisible(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  //why join animation
  const artistsRef = useRef<HTMLDivElement | null>(null);
  const fansRef = useRef<HTMLDivElement | null>(null);
  const [isArtistsVisible, setArtistsVisible] = useState(false);
  const [isFansVisible, setFansVisible] = useState(false);

  const checkVisibility = () => {
    const windowHeight = window.innerHeight;
    const artistsPos = artistsRef.current
      ? artistsRef.current.getBoundingClientRect().top
      : 0;
    const fansPos = fansRef.current
      ? fansRef.current.getBoundingClientRect().top
      : 0;

    if (artistsPos < windowHeight * 0.75 && !isArtistsVisible) {
      setArtistsVisible(true);
    }
    if (fansPos < windowHeight * 0.75 && !isFansVisible) {
      setFansVisible(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", checkVisibility);
    checkVisibility();

    return () => {
      window.removeEventListener("scroll", checkVisibility);
    };
  }, []);

  //augio benefits animation
  const [isArtistBenefitsVisible, setArtistBenefitsVisible] = useState(false);
  const [isFanBenefitsVisible, setFanBenefitsVisible] = useState(false);

  const artistBenefitsRef = useRef<HTMLDivElement | null>(null);
  const fanBenefitsRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (artistBenefitsRef.current) {
        const artistOffset =
          artistBenefitsRef.current.getBoundingClientRect().top;
        const triggerPosition = window.innerHeight * 0.75;

        if (artistOffset <= triggerPosition && !isArtistBenefitsVisible) {
          setArtistBenefitsVisible(true);
        }
      }

      if (fanBenefitsRef.current) {
        const fanOffset = fanBenefitsRef.current.getBoundingClientRect().top;
        const triggerPosition = window.innerHeight * 0.75;

        if (fanOffset <= triggerPosition && !isFanBenefitsVisible) {
          setFanBenefitsVisible(true);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isArtistBenefitsVisible, isFanBenefitsVisible]);

  //features carousel
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isSliding, setIsSliding] = useState(false);
  const slideTimeout = useRef<NodeJS.Timeout | null>(null);
  const [isFeatureVisible, setFeatureVisible] = useState(false);
  const featureRef = useRef<HTMLDivElement | null>(null);

  const features = [
    {
      image: "region_screenshot.png",
      title: "Regions",
      quote:
        '“My music now has a dedicated home where fans can effortlessly discover it in a unified feed every time I upload. This innovative feature sets it apart from other major platforms and promises to revolutionize the way new fans explore and discover my music and that of fellow artists." - Beta Tester Feedback',
      description:
        "Now, every time a song is uploaded, it has a place to live and can be effortlessly discovered by fans.",
    },

    {
      image: "statechart_screenshot.png",
      title: "State Charts",
      quote:
        '“This is incredible. Providing independent artists with a real opportunity to shine every day and take center stage. Its also fun to check the charts daily to see where you stand!" -Beta Tester Feedback',
      description:
        "Every state has amazing artists. Augio state charts are updated daily to highlight the top 50 artists in each state. No paid spots, no bias, no secret algorithms.",
    },

    {
      image: "recommendsong_screenshot.png",
      title: "Recommend Songs In-App",
      quote:
        '“Now I can easily share music with friends in the app, send tracks directly to them, get music suggestions as notifications, and enjoy discovering new music with friends in a simpler and more enjoyable way." - Beta Tester Feedback',
      description:
        "Music Fans - Say goodbye to texting song links. Augio makes sharing new music with friends fun and easy.",
    },

    {
      image: "promote_screenshot.png",
      title: "Music Promotion Tools",
      quote:
        "This is a game-changer! I can see where my music is being played and target fans by region and genre. It's never been easier to reach new listeners. - Artist Feedback",
      description:
        "Artists — Promote your music with ease by targeting fans in any state or region, and focus on listeners who enjoy your genre for maximum impact.",
    },

    {
      image: "buysong_screenshot.png",
      title: "Earn More From Your Music",
      quote:
        "“Augio empowers fans to buy your music and receive the earnings you deserve, rather than earning just a few cents monthly from prominent streaming platforms.” -Beta Tester Feedback",
      description:
        "Artists - Keep 100% of the money you make from selling your music on Augio.",
    },
    {
      image: "recommendations_screenshot.png",
      title: "Better Recommendations",
      quote: "",
      description:
        "Augio's proprietary algorithm uses more realistic data attributes to recommend new music to you.",
    },
    {
      image: "recommendations_screenshot.png",
      title: "Fan Interaction Tools",
      quote: "",
      description:
        "Artists - Notify your fans with in-app notifications about upcoming releases, shows, and merch drops.",
    },

    {
      image: "top100_screenshot.png",
      title: "Augio Top 100",
      quote:
        "In the world of independent music, this is the chart everyone wants to be on",
      description:
        "In the world of independent music, this is the chart to be on.",
    },
  ];

  const featureTitleColors = [
    "#C60404",
    "#5D04B7",
    "#DE9407",
    "#15BDCB",
    "#1CCB15",
    "#4615CB",
    "#93CB15",
    "#f40e2b",
  ]; //colors for feature titles

  useEffect(() => {
    const interval = setInterval(() => {
      nextFeature();
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (isSliding) {
      slideTimeout.current = setTimeout(() => {
        setIsSliding(false);
      }, 700);
    }
    return () => {
      if (slideTimeout.current) clearTimeout(slideTimeout.current);
    };
  }, [isSliding, currentIndex]);

  const nextFeature = () => {
    setIsSliding(true);
    setCurrentIndex((prevIndex) => (prevIndex + 1) % features.length);
  };

  const prevFeature = () => {
    setIsSliding(true);
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + features.length) % features.length
    );
  };

  //region and charts content animation
  const [isContentVisible, setContentVisible] = useState(false);
  const contentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (contentRef.current) {
        const contentOffset = contentRef.current.getBoundingClientRect().top;
        const triggerPosition = window.innerHeight * 0.95;

        if (contentOffset <= triggerPosition) {
          setContentVisible(true);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitting(true);

    setSubmitSuccess("Sent! Check Your Inbox!");
    setSubmitError("");
    setFadeSuccess(false);

    try {
      await saveEmailToFirestore(email);
      const sendWaitlistEmail = httpsCallable(functions, "sendWaitlistEmail");
      await sendWaitlistEmail({ to: email });

      logCustomEvent("joined_waitinglist_event");

      setEmail("");
      setIsSubmitting(false);

      // fade out animation
      setTimeout(() => {
        setFadeSuccess(true);

        setTimeout(() => {
          setSubmitSuccess("");
          setFadeSuccess(false);
        }, 1000);
      }, 1000);
    } catch (error) {
      console.error("Error sending welcome email:", error);

      setSubmitError("Failed to send. Try again.");
      setSubmitSuccess("");

      setIsSubmitting(false);
    }
  };

  const saveEmailToFirestore = async (email: string) => {
    try {
      await addDoc(collection(firestore, "waitingListEmails"), {
        email: email,
        timestamp: new Date(),
      });
      console.log(
        "Email saved to Firestore, trigger should send an email shortly."
      );
    } catch (error) {
      console.error("Error saving email to Firestore:", error);
      throw new Error("Failed to save email to Firestore.");
    }
  };

  return (
    <div>
      <div className="landing-page-container">
        <div>
          <div className="menu-links">
            <img className="augio-tranparent-g" src={gLogoUrl}></img>

            <div>
              {/*<Link className="register-btn menu-link-spacing" to="/register">
                Register
              </Link>*/}
              <Link className="login-btn menu-link-spacing" to="/login">
                Login
              </Link>
            </div>
          </div>

          <div className="top-page-text">
            <div className="landing-image-text-container">
              <img className="augio-landing-image" src={mapImage}></img>
              <div className="custom-font-group">
                <div
                  className={`text-over-image-01 custom-header-font ${
                    animateText ? "slide-in" : "text-offscreen"
                  }`}
                >
                  Open The Door
                </div>
                <div
                  className={`text-over-image-02 custom-header-font ${
                    animateText ? "slide-in" : "text-offscreen"
                  }`}
                >
                  To A Whole
                </div>
                <div
                  className={`text-over-image-03 custom-header-font ${
                    animateText ? "slide-in" : "text-offscreen"
                  }`}
                >
                  New World
                </div>
                <div
                  className={`text-over-image-04 custom-header-font ${
                    animateText ? "slide-in" : "text-offscreen"
                  }`}
                >
                  Of Music
                </div>
                <div
                  className={`text-over-image-05 custom-header-font ${
                    animateText ? "slide-in" : "text-offscreen"
                  }`}
                >
                  Discovery
                </div>
              </div>
            </div>
          </div>

          <div className="intro-text">
            <h1
              className={`landing-large-text fade-up-init ${
                isVisible ? "fade-up-final" : ""
              }`}
            >
              INTRODUCING
            </h1>
            <img
              className={`landing-logo fade-up-init ${
                isVisible ? "fade-up-final" : ""
              }`}
              src={logoUrl}
              alt="augio-colorized-mock"
            />
            <p
              className={`introducing-quote-text fade-up-init ${
                isVisible ? "fade-up-final" : ""
              }`}
            >
              Augio is redefining music streaming by introducing an innovative,
              independent-artist-only platform that uses location-based music
              organization to amplify artists and simplify music discovery for
              fans.
            </p>
          </div>

          <section className="why-join-section">
            <div
              ref={artistsRef}
              className={`artists-why-join ${
                isArtistsVisible ? "in-view" : ""
              }`}
            >
              <p className="why-join-artists">ARTISTS</p>
              <p className="why-join-description">
                Building a fanbase and earning from your music starts with being
                found. This is why it's critical to make your music easily
                accessible to fans.
              </p>
            </div>

            <div
              ref={fansRef}
              className={`fans-why-join ${isFansVisible ? "in-view" : ""}`}
            >
              <p className="why-join-fans">MUSIC FANS</p>
              <p className="why-join-description">
                Independent artists make up 96% of daily music uploads, yet
                major streaming services often overlook these emerging talents,
                leaving a world of new music and artists undiscovered.
              </p>
            </div>
          </section>

          <section className="augio-benefits">
            <div className="artist-benefits" ref={artistBenefitsRef}>
              <div
                className={`benefits-title artist-benefits-header ${
                  isArtistBenefitsVisible
                    ? "benefits-text-final"
                    : "benefits-text-init"
                }`}
              >
                ARTISTS
              </div>
              <ul className="benefits-list">
                <li
                  className={`benefit-item-text ${
                    isArtistBenefitsVisible
                      ? "benefits-text-final"
                      : "benefits-text-init"
                  }`}
                >
                  No Major Artists or Labels
                </li>
                <li
                  className={`benefit-item-text ${
                    isArtistBenefitsVisible
                      ? "benefits-text-final"
                      : "benefits-text-init"
                  }`}
                >
                  No Paid Playlists
                </li>
                <li
                  className={`benefit-item-text ${
                    isArtistBenefitsVisible
                      ? "benefits-text-final"
                      : "benefits-text-init"
                  }`}
                >
                  Music Promotion Features
                </li>
                <li
                  className={`benefit-item-text ${
                    isArtistBenefitsVisible
                      ? "benefits-text-final"
                      : "benefits-text-init"
                  }`}
                >
                  No Biased Partnerships
                </li>
                <li
                  className={`benefit-item-text ${
                    isArtistBenefitsVisible
                      ? "benefits-text-final"
                      : "benefits-text-init"
                  }`}
                >
                  Keep 100% of Profits from Music Sales
                </li>
              </ul>
            </div>

            <div className="fan-benefits" ref={fanBenefitsRef}>
              <div
                className={`benefits-title fans-benefits-header ${
                  isFanBenefitsVisible
                    ? "benefits-text-final"
                    : "benefits-text-init"
                }`}
              >
                MUSIC FANS
              </div>
              <ul className="benefits-list">
                <li
                  className={`benefit-item-text ${
                    isFanBenefitsVisible
                      ? "benefits-text-final"
                      : "benefits-text-init"
                  }`}
                >
                  Search Music Geographically
                </li>
                <li
                  className={`benefit-item-text ${
                    isFanBenefitsVisible
                      ? "benefits-text-final"
                      : "benefits-text-init"
                  }`}
                >
                  Charts for Every State
                </li>
                <li
                  className={`benefit-item-text ${
                    isFanBenefitsVisible
                      ? "benefits-text-final"
                      : "benefits-text-init"
                  }`}
                >
                  Feeds for Every Region
                </li>
                <li
                  className={`benefit-item-text ${
                    isFanBenefitsVisible
                      ? "benefits-text-final"
                      : "benefits-text-init"
                  }`}
                >
                  Recommended Artists Near You
                </li>
                <li
                  className={`benefit-item-text ${
                    isFanBenefitsVisible
                      ? "benefits-text-final"
                      : "benefits-text-init"
                  }`}
                >
                  Send Songs to Friends In-App
                </li>
              </ul>
            </div>
          </section>

          <section className="features-section">
            <h2 className="custom-font-heading">
              Changing The <br></br>Streaming Landscape
            </h2>
            <div className="carousel-wrapper">
              <div
                className={`features-container ${isSliding ? "sliding" : ""}`}
                style={{ transform: `translateX(-${currentIndex * 100}%)` }}
              >
                {features.map((feature, index) => (
                  <div key={index} className="feature-content">
                    <div className="feature-text-group">
                      <p
                        className="feature-title"
                        style={{
                          color:
                            featureTitleColors[
                              index % featureTitleColors.length
                            ],
                        }}
                      >
                        {feature.title}
                      </p>
                      <p className="feature-description">
                        {feature.description}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="carousel-controls">
              <RiArrowLeftSLine
                className="carousel-arrow"
                onClick={prevFeature}
              />
              <RiArrowRightSLine
                className="carousel-arrow"
                onClick={nextFeature}
              />
            </div>
          </section>

          <div
            className={`main-content-section ${
              isContentVisible ? "visible" : ""
            }`}
            ref={contentRef}
          >
            <div className="main-content-left">
              <div className="main-content-title">
                Augio uniquely
                <br></br>
                organizes music
                <br></br>
                by location
                <br></br>
                to make it
                <br></br>
                stand out
              </div>
              <p className="main-content-subtext">
                Sorting music by location amplifies your music's visibility,
                helping music fans and potential collaborators discover your
                music easier.
              </p>

              <div className="main-content-sub-container">
                <div className="how-it-works-header">Augio Charts</div>
                <p className="how-it-works-subtext">
                  There's a chart for every state, giving artists a new way to
                  standout and music fans exciting new ways to discover music.
                </p>
                <p className="how-it-works-subtext">
                  Everyone's music should have an equal shot at recognition, so
                  we don't use biased algorithms or let anyone pay for chart
                  positions.
                </p>
              </div>
            </div>

            <div className="main-content-right">
              <img
                className="zones-group-desktop"
                src="https://augio-other-images.s3.amazonaws.com/zones-group-landing-page.png"
              ></img>

              <div className="how-it-works-header">Augio Regions</div>

              <p className="how-it-works-subtext">
                Each state is broken into two to three regions based on
                population density. All the music you upload is added to your
                regions's uploads page, giving fans an easy way to find your
                music.
              </p>
              <p className="how-it-works-subtext">
                100,000+ songs are uploaded to streaming platforms daily.
                Augio's innovative sorting methods increase exposure to music.
              </p>
            </div>
          </div>

          <div className="signup-container">
            <div className="musicfans-waiting-list-container">
              <p className="musicfans-join-title">MUSIC FANS</p>
              <p className="musicfans-join-subtext">Join Waiting List</p>
              <div className="signup-info">
                <form className="waitlist-form" onSubmit={handleSubmit}>
                  <input
                    className="signup-input"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email"
                    required
                  />
                  <button
                    className="signup-submit-btn"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Join
                  </button>
                </form>
                {submitSuccess && (
                  <div
                    className={`waitlist-email-success ${
                      fadeSuccess ? "fade-out" : ""
                    }`}
                  >
                    {submitSuccess}
                  </div>
                )}

                {submitError && (
                  <div className="waitlist-email-error">{submitError}</div>
                )}
              </div>
            </div>

            <div className="artists-signup-container">
              <p className="artists-signup-title">Artists</p>
              <p className="artists-join-subtext">Sign Up</p>
              <Link className="artists-register-link" to="/register">
                Register
              </Link>
            </div>
          </div>

          <section className="mobile-app-preview-section">
            <h2 className="custom-font-heading">
              Augio iOS App<br></br>Coming Soon
            </h2>
            <img className="ios-app-image" src={iOSAppImage}></img>
          </section>

          <p className="founder-quote">
            "We created Augio to give independent and up-and-coming artists and
            music creators the main stage by solving major problems with current
            streaming platforms. This opens new doors for music fans to discover
            new artists and music like never before."
          </p>

          <p className="quote-credit">Joe Murphy</p>
          <p className="quote-credit">Founder of Augio</p>

          <div className="bottom-page-content"></div>
        </div>
      </div>

      <footer>
        <div className="footer-entire-group">
          <div className="footer-links-group">
            <h6>Company</h6>
            <p className="footer-link">
              <HiOutlineMail className="footer-icon" />
              Press: info@augio.io
            </p>
            <p className="footer-link">
              <MdOutlineContactSupport className="footer-icon" />
              Support: support@augio.io
            </p>
            <p className="footer-link">
              <MdOutlineArticle className="footer-icon" />
              <Link
                className="footer-link clickable-link"
                target="_blank"
                to="https://augio.medium.com/"
              >
                Blog
              </Link>
            </p>
          </div>
          <div className="footer-links-group">
            <h6>Quick Links</h6>
            <Link
              className="footer-link quick-link"
              target="_blank"
              to="/terms"
            >
              Terms
            </Link>
            <Link
              className="footer-link quick-link"
              target="_blank"
              to="/membership-terms"
            >
              Membership Agreement
            </Link>

            <Link
              className="footer-link quick-link"
              target="_blank"
              to="/privacy-policy"
            >
              Privacy Policy
            </Link>
          </div>
          <div className="footer-links-group">
            <h6>Social</h6>

            <div className="social-link-row">
              <a
                className="footer-link quick-link"
                href="https://www.twitter.com/augiomusic_io"
                target="_blank"
              >
                <BsTwitter className="footer-icon"></BsTwitter>
              </a>
              <p className="social-link-name">Twitter</p>
            </div>
            <div className="social-link-row">
              <a
                className="footer-link"
                href="https://www.instagram.com/augio.io"
                target="_blank"
              >
                <AiFillInstagram className="footer-icon"></AiFillInstagram>
              </a>
              <p className="social-link-name">Instagram</p>
            </div>
            <div className="social-link-row">
              <a
                className="footer-link"
                href="https://www.tiktok.com/@augio.io"
                target="_blank"
              >
                <FaTiktok className="footer-icon"></FaTiktok>
              </a>
              <p className="social-link-name">TikTok</p>
            </div>
          </div>
        </div>
        <div className="augio-copyright">© 2024 AUGIO LLC</div>
      </footer>
    </div>
  );
};
