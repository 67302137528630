import { firestore } from '../firebase';
import {
  query,
  collection,
  where,
  getDoc,
  getDocs,
  doc,
  updateDoc,
  deleteDoc,
  runTransaction,
  serverTimestamp,
  increment,
  orderBy,
  QuerySnapshot,
  DocumentData,
  QueryDocumentSnapshot,
  limit
} from 'firebase/firestore';
import { Track } from "../Types/Types"

export class FeedService {

  static async getUserIdByUsername(username: string): Promise<string | null> {
    const usersRef = collection(firestore, 'users');
    const q = query(usersRef, where("userName", "==", username)); 
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      return querySnapshot.docs[0].id;
    } else {
      console.log("No user found with username:", username);
      return null;
    }
  }


  // follow a user and update the followed user's fan count atomically
  static async followUser(followerId: string, followedUserId: string): Promise<void> {
    const followedUserRef = doc(firestore, 'users', followedUserId);
    const followRef = doc(firestore, 'following', `${followerId}_${followedUserId}`);

    console.log(`Follower ID: ${followerId}, Followed User ID: ${followedUserId}`);


    try {
      await runTransaction(firestore, async (transaction) => {
        const followedUserDoc = await transaction.get(followedUserRef);
        if (!followedUserDoc.exists()) {
          throw new Error("Followed user does not exist!");
        }
        transaction.update(followedUserRef, { fans: increment(1) });
        // record the follow relationship
        transaction.set(followRef, { followerId, followedUserId, dateFollowed: serverTimestamp() });
      });
      console.log("Followed user successfully.");
    } catch (e) {
      console.error("Following user failed", e);
    }
  }

  // unfollow a user and update the unfollowed user's fan count atomically
  static async unfollowUser(followerId: string, followedUserId: string): Promise<void> {
    const followedUserRef = doc(firestore, 'users', followedUserId);
    const followRef = doc(firestore, 'following', `${followerId}_${followedUserId}`);

    try {
      await runTransaction(firestore, async (transaction) => {
        const followedUserDoc = await transaction.get(followedUserRef);
        if (!followedUserDoc.exists()) {
          throw new Error("Followed user does not exist!");
        }
        transaction.update(followedUserRef, { fans: increment(-1) });
        // remove the follow relationship
        transaction.delete(followRef);
      });
      console.log("Unfollowed user successfully.");
    } catch (e) {
      console.error("Unfollowing user failed", e);
    }
  }

  static async isFollowing(followerId: string, followedUserId: string): Promise<boolean> {
    const followingRef = collection(firestore, 'following');
    const q = query(followingRef, where('followerId', '==', followerId), where('followedUserId', '==', followedUserId));
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty; 
  }


  static async getFeed(userId: string, filter: string = 'recent'): Promise<Track[]> {
    const followingQuery = query(collection(firestore, 'following'), where('followerId', '==', userId));
    const followingDocs = await getDocs(followingQuery);
    const followedUserIds = followingDocs.docs.map(doc => doc.data().followedUserId);

    let trackQueries: Promise<QuerySnapshot>[] = [];

    followedUserIds.forEach(followedUserId => {
      const tracksRef = collection(firestore, "tracks");
      let q;

      switch (filter) {
        case 'recent':
          q = query(tracksRef, where("userId", "==", followedUserId), orderBy("releaseDate", "desc"));
          break;
        case 'singles':
          q = query(tracksRef, where("userId", "==", followedUserId), where("uploadType", "==", "single"), orderBy("releaseDate", "desc"));
          break;
        case 'albums':
          q = query(tracksRef, where("userId", "==", followedUserId), where("uploadType", "==", "album"), orderBy("releaseDate", "desc"));
          break;
        default:
          q = query(tracksRef, where("userId", "==", followedUserId), orderBy("releaseDate", "desc"));
      }

      trackQueries.push(getDocs(q));
    });

    const trackResults = await Promise.all(trackQueries);

    let tracks: Track[] = [];
    trackResults.forEach(results => {
      results.docs.forEach(doc => {
        const trackData = doc.data() as DocumentData;
        tracks.push({
          id: doc.id,
          trackTitle: trackData.trackTitle,
          userName: trackData.userName,
          artistName: trackData.artistName,
          genre: trackData.genre,
          coverArtUrl: trackData.coverArtUrl,
          chartPosition: trackData.chartPosition,
          releaseDate: trackData.releaseDate?.toDate ? trackData.releaseDate.toDate() : trackData.releaseDate,
          audioFileUrl: trackData.audioFileUrl,
          featureArtists: trackData.featureArtists,
          producers: trackData.producers,
          songwriters: trackData.songwriters,
          engineers: trackData.engineers
        });
      });
    });

    if (filter === 'recent') {
      tracks.sort((a, b) => {
        return (b.releaseDate as Date).getTime() - (a.releaseDate as Date).getTime();
      });
    }

    if (filter === 'singles') {
      tracks.sort((a, b) => {
        return (b.releaseDate as Date).getTime() - (a.releaseDate as Date).getTime();
      });
    }

    if (filter === 'albums') {
      tracks.sort((a, b) => {
        return (b.releaseDate as Date).getTime() - (a.releaseDate as Date).getTime();
      });
    }

    if (filter === 'randomize') {
      tracks = shuffleArray(tracks);
    }

    return tracks;
  }
}

  const shuffleArray = (array: Track[]): Track[] => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };