import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    // set scroll behavior to jump immediately to the top
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto' 
    });
  }, [pathname]); // triggered every time the pathname changes

  return null; // this component does not render anything
};


