import React, { useState, useEffect, useContext } from "react";
import { Toolbar } from "./Toolbar";
import { SideNavigation } from "./SideNavigation";
import { AudioPlayer } from "./AudioPlayer";
import { AudioTrack } from "./AudioTrack"
import { Track } from '../Types/Types'
import { useNavigate, useParams } from "react-router-dom";
import "../Styles/Region.css";
import { sortedGenres } from "../Utilities/StaticFormData";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { FaRegHeart, FaHeart } from "react-icons/fa";
import { RegionService } from "../Services/RegionService"
import { auth } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";
import { firestore } from '../firebase';
import { collection, getDocs, query, where, orderBy, DocumentData, Query } from 'firebase/firestore';
import { SideNavigationContext } from "../Context/SideNavigationContext";
import { AudioPlayerContext } from '../Context/AudioPlayerContext';
import { logCustomEvent } from "../Utilities/EventConfig";





const sortOptions = {
  RECENT: "recent",
  POPULAR: "popular",
  RANDOMIZE: "randomize",
};


export const Region = () => {
  const [isGenresVisible, setIsGenresVisible] = useState(false);
  const [isFavorited, setIsFavorited] = useState(false); 
  const [userId, setUserId] = useState<string | null>(null); 
  const { regionNameAlias } = useParams(); 
  const [regionName, setRegionName] = useState("")
  const [regionId, setRegionId] = useState<string | null>(null);
  const [fansCount, setFansCount] = useState(0); 
  const [tracks, setTracks] = useState<Track[]>([]);
  const [sortOrder, setSortOrder] = useState(sortOptions.RECENT);
  const [selectedGenres, setSelectedGenres] = useState<number[]>([]); 
  const context = useContext(SideNavigationContext);

  if (!context) {
    return null;
  }

  const { addFavoriteRegion, removeFavoriteRegion } = context



  const fetchRegionIdFromAlias = async (alias: string | undefined): Promise<string | undefined> => {
    if (!alias) {
      console.error("No alias provided");
      return undefined;
    }

    const formattedAlias = alias.charAt(0).toUpperCase() + alias.slice(1).toLowerCase();
    console.log(`Fetching regionID for formatted alias: '${formattedAlias}'`);

    const regionsRef = collection(firestore, "regions");
    const q = query(regionsRef, where("regionNameAlias", "==", formattedAlias));

    try {
      const querySnapshot = await getDocs(q);
      if (querySnapshot.empty) {
        console.error(`No matching region found for alias: '${formattedAlias}'`);
        return undefined;
      }
      const regionDoc = querySnapshot.docs[0];
      const regionID = regionDoc.data().regionID; 
      console.log(`Fetched regionID: ${regionID} for alias: '${formattedAlias}'`);
      return regionID;
    } catch (error) {
      console.error("Error fetching regionID from alias:", error);
      return undefined;
    }
  };

  useEffect(() => {
    const fetchTracks = async () => {
      const regionID = await fetchRegionIdFromAlias(regionNameAlias);
      if (!regionID) {
        console.error("Failed to fetch regionID for alias:", regionNameAlias);
        return;
      }

      let baseQuery = query(collection(firestore, "tracks"), where("regionID", "==", regionID));

      switch (sortOrder) {
        case sortOptions.RECENT:
          baseQuery = query(baseQuery, orderBy("releaseDate", "desc"));
          break;
        case sortOptions.POPULAR:
          baseQuery = query(baseQuery, orderBy("allTimePlays", "desc"), orderBy("releaseDate", "asc"));
          break;
      }

      const querySnapshot = await getDocs(baseQuery);
      let fetchedTracks = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...(doc.data() as Omit<Track, "id">),
      }));

      if (selectedGenres.length > 0) {
        const genreNames = selectedGenres.map(
          (id) => sortedGenres.find((genre) => genre.genreID === id)?.genreName
        );
        fetchedTracks = fetchedTracks.filter((track) =>
          genreNames.includes(track.genre)
        );
      }


      if (sortOrder === sortOptions.RANDOMIZE) {
        fetchedTracks = shuffleArray(fetchedTracks);
      }

      setTracks(fetchedTracks);
    };

    fetchTracks();
  }, [regionNameAlias, selectedGenres, sortOrder]);


  const shuffleArray = (array: Track[]) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const randomizeTracks = () => {
    setTracks(prevTracks => shuffleArray([...prevTracks]));
  };

  const getButtonClass = (order: string) => {
    return `filter-btn ${sortOrder === order ? "selected" : ""}`;
  };



  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
        if (regionId) {
          checkFavoriteStatus(); 
        }
      } else {
        setUserId(null);
        setIsFavorited(false); 
      }
    });

    return () => unsubscribe();
  }, [regionId]); 

  useEffect(() => {
    if (regionNameAlias) {
      RegionService.getRegionIdByRegionNameAlias(regionNameAlias)
        .then(fetchedRegionId => {
          setRegionId(fetchedRegionId);
          RegionService.getFansCountByRegionId(fetchedRegionId)
            .then(fetchedFansCount => {
              setFansCount(fetchedFansCount);
            })
            .catch(error => {
              console.error("Error fetching fans count:", error);
            });
          RegionService.getRegionNameByAlias(regionNameAlias)
            .then(fetchedRegionName => {
              setRegionName(fetchedRegionName);
            })
            .catch(error => {
              console.error("Error fetching region name:", error);
            });
          if (userId) {
            checkFavoriteStatus();
          }
        })
        .catch(error => {
          console.error("Error fetching region ID:", error);
        });
    }
  }, [regionNameAlias, userId]);


  const checkFavoriteStatus = async () => {
    if (userId && regionId) {
      const favoriteRegions = await RegionService.fetchFavoriteRegions(userId);
      const isRegionFavorited = favoriteRegions.some(region => region.id === regionId);
      setIsFavorited(isRegionFavorited);
    }
  };

  const toggleFavorite = async () => {
    if (userId && regionId) {
      setIsFavorited(currentState => !currentState); 
      try {
        if (!isFavorited) {
          await RegionService.toggleFavoriteRegion(userId, regionId, true);
          const newRegion = { id: regionId, name: regionName, regionNameAlias: regionNameAlias };
          addFavoriteRegion(newRegion);
          logCustomEvent('Region_Favorited', {
            regionId: regionId,
            regionState: regionName,
            action: 'add',
            userId: userId  
          });
        } else {
          await RegionService.toggleFavoriteRegion(userId, regionId, false);
          removeFavoriteRegion(regionId);
          logCustomEvent('Region_Unfavorited', {
            regionId: regionId,
            regionState: regionName,
            action: 'remove',
            userId: userId  
          });
        }
        const updatedFansCount = await RegionService.getFansCountByRegionId(regionId);
        setFansCount(updatedFansCount); 
      } catch (error) {
        console.error("Error toggling favorite status:", error);
        setIsFavorited(currentState => !currentState);
      }
    } else {
      console.error("User ID or Region ID is missing");
    }
  };
  


  const toggleGenres = () => {
    setIsGenresVisible(!isGenresVisible); 
  };

  const toggleGenreSelection = (genreID: number) => {
    setSelectedGenres(prevSelectedGenres => {
      if (prevSelectedGenres.includes(genreID)) {
        return prevSelectedGenres.filter(id => id !== genreID);
      } else {
        return [...prevSelectedGenres, genreID];
      }
    });
  };


  return (
    <div className="region-page-container">
      <section className="inner-page-layout">
        <section className="inner-page-content">
          <div className="region-banner">
            <h1 className="region-name region-details-spacing">{regionName}</h1>
            <h2 className="region-fans region-details-spacing">
              {fansCount.toLocaleString()} Fans
            </h2>

            <button
              className="region-favorite-btn region-details-spacing"
              onClick={toggleFavorite}
            >
              {isFavorited ? <FaHeart /> : <FaRegHeart />}
              Favorite
            </button>
          </div>
          <section className="region-content-container">
            <button
              onClick={() => setSortOrder(sortOptions.RECENT)}
              className={getButtonClass(sortOptions.RECENT)}
            >
              Recent
            </button>
            <button
              onClick={() => setSortOrder(sortOptions.POPULAR)}
              className={getButtonClass(sortOptions.POPULAR)}
            >
              Popular
            </button>
            <button
              onClick={() => {
                setSortOrder(sortOptions.RANDOMIZE);
                randomizeTracks();
              }}
              className={getButtonClass(sortOptions.RANDOMIZE)}
            >
              Randomize
            </button>

            <button className="genre-filter-title" onClick={toggleGenres}>
              Filter By Genre
              {isGenresVisible ? <FaChevronUp /> : <FaChevronDown />}
            </button>

            {isGenresVisible && (
              <section className="region-genre-filters">
                {sortedGenres.map((genre) => (
                  <button
                    key={genre.genreID}
                    className={`region-genre-btn ${
                      selectedGenres.includes(genre.genreID)
                        ? "region-genre-btn-selected"
                        : ""
                    }`}
                    onClick={() => toggleGenreSelection(genre.genreID)} 
                  >
                    {genre.genreName}
                  </button>
                ))}
              </section>
            )}

            <section className="region-tracks-container">
              {tracks.map((track) => (
                <AudioTrack
                  key={track.id}
                  track={track}
                  feedIdentifier="region"
                  feedTracks={tracks}
                />
              ))}
            </section>
          </section>
        </section>
      </section>
    </div>
  );
};
