import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import "../Styles/Login.css";
import { Spinner } from "../GlobalStyles/Spinner";

export const ForgotPassword = () => {
  const logoUrl: string =
    "/Augio_FullType_Logo_latest.png";
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate(); 


  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const auth = getAuth();
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage("Password reset email sent!");
      setTimeout(() => {
        setEmail("");
        setMessage(""); 
    }, 2000);
    } catch (error: any) {
      setMessage("Failed to send password reset email. " + error.message);
    }
  };

  return (
    <div className="login-form-container">
      <form className="login-form" onSubmit={handleSubmit}>
        <Link className="login-logo-link" to="/">
          <img src={logoUrl} alt="Logo" className="login-logo" />
        </Link>
        <div className="login-header-text">Request Password Reset</div>
        <label className="create-account-form-label">
          Email<span style={{ color: "#D42616" }}></span>
        </label>
        <input
          className="login-form-input"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />{" "}
        <button className="login-form-btn" type="submit">
          Send Reset Email
        </button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};
