import React, { useState, useEffect } from "react";
import { Toolbar } from "../Components/Toolbar";
import { SideNavigation } from "./SideNavigation";
import { TrendingCharts } from "./TrendingCharts";
import { TrendingRegions } from "./TrendingRegions";
import { AudioPlayer } from "./AudioPlayer";
import "../Styles/Discover.css";
import { sortedStates } from "../Utilities/StaticFormData";
import { FilterChartRegion } from "../Services/ChartRegionFilter";
import { useNavigate } from "react-router-dom"; 






export const Discover = () => {
  const [selectedStates, setSelectedStates] = useState(new Set<number>());
  const [chartLinks, setChartLinks] = useState<any[]>([]);
  const [regionLinks, setRegionLinks] = useState<any[]>([]);
  const navigate = useNavigate();
  const filterService = new FilterChartRegion();


  useEffect(() => {
    const fetchChartsAndRegions = async () => {
      if (selectedStates.size > 0) {
        const stateIDsArray = Array.from(selectedStates);
        const charts = await filterService.fetchStateCharts(stateIDsArray);
        const regions = await filterService.fetchRegions(stateIDsArray);
        setChartLinks(charts);
        setRegionLinks(regions);
      } else {
        setChartLinks([]);
        setRegionLinks([]);
      }
    };

    fetchChartsAndRegions();
  }, [selectedStates]);


  const toggleStateSelection = (stateID: number) => {
    setSelectedStates((prevSelectedStates) => {
      const newSelectedStates = new Set(prevSelectedStates);
      if (newSelectedStates.has(stateID)) {
        newSelectedStates.delete(stateID);
      } else {
        newSelectedStates.add(stateID);
      }
      return newSelectedStates;
    });
  };

  const handleNavigate = (alias: string, type: 'chart' | 'region') => {
    navigate(`/${type}/${alias}`); 
  };



  return (
    <div className="page-container">
      <section className="inner-page-layout">
        <section className="inner-page-content">
          <h1 className="header-title">Discover</h1>

          <section className="discover-filter-section">
            <section className="state-filters">
              <h2 className="discover-subtext">Filter by State</h2>
              <h2 className="subheader-subtext">
                Select a State to Filter Charts and Regions
              </h2>
              {sortedStates.map((state) => (
                <button
                  key={state.stateID}
                  className={`state-filter-btn ${
                    selectedStates.has(state.stateID)
                      ? "state-filter-btn-selected"
                      : ""
                  }`}
                  onClick={() => toggleStateSelection(state.stateID)}
                >
                  {state.stateName}
                </button>
              ))}
            </section>

            <hr className="discover-divider"></hr>

            <section className="chart-region-filter-container">
              <p className="discover-subtitle">Filtered Charts</p>
              <div className="filter-row">
                {selectedStates.size === 0 ? (
                  <p className="disover-filter-help-text">
                    Select a state to see charts
                  </p>
                ) : (
                  chartLinks.map((chart) => (
                    <div
                      key={chart.id}
                      className="chart-region-filter-item"
                      onClick={() =>
                        handleNavigate(chart.stateNameAlias, "chart")
                      }
                    >
                      <div className="chart-region-filter-image">
                        <img
                          className="chart-filter-state-cover-image"
                          src={chart.stateImage}
                          alt={chart.stateName}
                        />
                      </div>
                      <p className="chart-region-filter-name">
                        {chart.stateName}
                      </p>
                    </div>
                  ))
                )}
              </div>

              <p className="discover-subtitle">Filtered Regions</p>
              <div className="filter-row">
                {selectedStates.size === 0 ? (
                  <p className="disover-filter-help-text">
                    Select a state to see regions
                  </p>
                ) : (
                  regionLinks.map((region) => (
                    <div
                      key={region.id}
                      className="chart-region-filter-item"
                      onClick={() =>
                        handleNavigate(region.regionNameAlias, "region")
                      }
                    >
                      <div className="chart-region-filter-image">
                        <img
                          className="region-filter-region-cover-image"
                          src={region.regionImage}
                          alt={region.regionName}
                        />
                      </div>
                      <p className="chart-region-filter-name">
                        {region.regionName}
                      </p>
                    </div>
                  ))
                )}
              </div>
            </section>
          </section>
          <section>
            <h2 className="subheader-title">Trending State Charts</h2>
            <TrendingCharts />
          </section>
          <section>
            <h2 className="subheader-title">Trending Regions</h2>
            <TrendingRegions />
          </section>
        </section>
      </section>
    </div>
  );
};
