import { firestore } from '../firebase'
import { query, collection, where, getDocs } from 'firebase/firestore';



export class FilterChartRegion {

    async fetchStateCharts(stateIDs: number[]): Promise<any[]> {
        const chartsQuery = query(collection(firestore, "stateCharts"), where("stateID", "in", stateIDs));
        const querySnapshot = await getDocs(chartsQuery);
        return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    }

    async fetchRegions(stateIDs: number[]): Promise<any[]> {
        const regionsQuery = query(collection(firestore, "regions"), where("stateID", "in", stateIDs));
        const querySnapshot = await getDocs(regionsQuery);
        return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    }
}
